import React from 'react';
import { AppBar, IconButton, Button, Menu, MenuItem} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/Group5.png';

const useStyles = makeStyles(() => ({ 
    appBar: {
      top: 'auto',
      bottom: 0,
      justifyContent: 'center',
      backgroundColor: '#1C5D90'
    },
  }));
  
const NavBarInstance = ({ onSelect, activeKey, ...props }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const logout = () => {
        localStorage.removeItem('TOKEN')
        props.history.push('/')
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (text) => {
        console.log(text)
        props.changeAlertFormatForMobile(text)
        setAnchorEl(null);
    };
    return (
        <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar style={{justifyContent: 'space-between', flex : 1}}>
        <div>
            <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
            >
                <MenuItem onClick={() => handleClose('raw')}>Raw Alerts</MenuItem>
                <MenuItem onClick={() => handleClose('detailled')}>Detailled Alerts</MenuItem>
            </Menu>
        </div>
        <div style={{alignSelf: 'center', textAlign: 'center'}}>
            <img src={logo} height={20}></img>
        </div>
          <IconButton edge="end" color="inherit" onClick={logout}>
            <PowerSettingsNewIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  };
  
  export default NavBarInstance