import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import Info from '@material-ui/icons/InfoOutlined';
import { withStyles } from "@material-ui/styles";

// DISPLAY FLASH MESSAGE IN ACCORDIONS IN THRESHOLD MAP TO PREVENT USER HE CANT CHANGE THRESHOLD OF ALL COUNTRY,
// AND PREVENT WHEN HE WILL CHANGE ALL THRESHOLD IN DEPARTMENTS OR REGIONS 


    const InfoIcon = withStyles({
        root : {
        color : 'white',
        fontSizeLarge: '50px'
        }
    })(Info)

    const TypoWhite = withStyles({
        root : {
            color : 'white',
            marginRight: 10,
        }
    })(Typography)

export default class FlashMessage extends Component{
    constructor(props)
    {
        super(props);
        this.state = {
            message : "You can't change the threshold of the all country !",
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.current !== prevProps.current && this.props.current === '')
        {
            this.setState({
                message : "You can't change the threshold of the all country !"
            })
        }
        else if(this.props.current !== prevProps.current && this.props.current.includes('region'))
        {
            this.setState({
                message : "You will change all threshold's postcodes and departments in this region !"
            })
            this.Disapear()
        }
        else if( this.props.current !== prevProps.current && this.props.current.includes('departement'))
        {   
            this.setState({
                message : "You will change all threshold's postcodes in this department !"
            })
            this.Disapear()
        }
        else if( this.props.current !== prevProps.current && Number.isInteger(parseInt(this.props.current)))
        {
            this.setState({
                message : ''
            })
        }
    }
    
    clearMessage()
    {
        this.setState({
            message : ''
        })
    }

    Disapear()
    {
        setTimeout(() =>{
                this.setState({
                    message : ''
                })
            }, 5000)
    }
    
    render(){
        return(
                <div style={{display : this.state.message ? 'flex' : 'none' , backgroundColor : 'rgba(22, 76, 117, 0.9)', position: 'absolute', justifyContent : 'center', zIndex: 2, minHeight : '100%'}}>
                    <div style={{display: 'flex', flex : 1, justifyContent:'center', margin: 'auto'}}>
                        <InfoIcon style={{fontSize: '5em'}}/>
                    </div>
                    <div style={{display: 'flex', flex : 3, justifyContent:'center', textAlign: 'center', textAlign: 'center', margin: 'auto'}}>
                        <TypoWhite color="initial" variant="h6">{this.state.message}</TypoWhite>
                    </div>
                </div>
        )
    }
}