import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Group } from '@vx/group';
import { BarGroup } from '@vx/shape';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { AxisBottom } from '@vx/axis';
import { timeParse, timeFormat } from 'd3-time-format';

const bg = '#eaedff';

const margin = {
  left: 30,
  bottom: 100,
  right: 60,
  top: 80,
};

const format = timeFormat('%b %d %H:%M');
const formatDate = date => format(date);

// accessors
const x = d => d.date;

const AlertBarGroup = ({ data, keys, width, height }) => {
  // bounds
  const xMax = width;
  const yMax = height - margin.top - margin.bottom;

  // scales
  const x0Scale = scaleBand({
    domain: _.map(data, x),
    padding: 0.2,
  });

  const x1Scale = scaleBand({
    domain: keys,
    padding: 0.1,
  });

  const yScale = scaleLinear({
    domain: [0, _.max(_.reduce(data, (arr, d) => {
      arr.push(_.max(_.values(_.filter(d, (v, k) => k !== 'date'))))
      return arr
    }, []))],
    nice: true,
  }).range([yMax, 0]);

  const color = scaleOrdinal({
    domain: keys,
    range: ['#6c5efb', '#c998ff', '#a44afe'],
  });

  x0Scale.rangeRound([0, xMax]);
  x1Scale.rangeRound([0, x0Scale.bandwidth()]);

  return (
    <svg width={width} height={height}>
      <rect x={0} y={0} width={width} height={height} fill={bg} rx={14} />
      <Group top={margin.top}>
        <BarGroup
          data={data}
          keys={keys}
          height={yMax}
          x0={x}
          x0Scale={x0Scale}
          x1Scale={x1Scale}
          yScale={yScale}
          color={color}
        >
          {(barGroups) => {
            console.log(barGroups)
            return _.map(barGroups, barGroup => {
              return (
                <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                  {_.map(barGroup.bars, bar => {
                    return (
                      <rect
                        key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                        x={bar.x}
                        y={bar.y}
                        width={bar.width}
                        height={bar.height}
                        fill={bar.color}
                        rx={4}
                      />
                    );
                  })}
                </Group>
              );
            });
          }}
        </BarGroup>
      </Group>
      <AxisBottom
        scale={x0Scale}
        tickFormat={formatDate}
        top={yMax + margin.top}
      />
    </svg>
  );
};

AlertBarGroup.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

AlertBarGroup.defaultProps = {
  width: 800,
  height: 480,
};

export default AlertBarGroup;
