import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
  questionsAerial,
  questionsStreet,
  questionsOther,
} from '../data/survey';
import RadioButton from '../components/RadioButton';
import RatingComponent from '../components/RatingComponent';
import SliderInput from '../components/SliderInput';
import EditBox from '../components/EditBox';
import AerialMailIcon from '../components/SvgIcons/AerialMail';
import BlueStartIcon from '../components/SvgIcons/BlueStart';
import SatelliteIcon from '../components/SvgIcons/Satellite';
import StreetViewIcon from '../components/SvgIcons/StreetView';
import NoImages from '../assets/icon-front/no-images.png';
import ImgToit from '../assets/images/img-toit.jpg';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import mergeImages from 'merge-images';

import 'react-google-places-autocomplete/dist/assets/index.css';

import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

const API_KEY = 'AIzaSyCgRe9A3oOAl9FRWyMb0ejcSZ1sI9IbUKg';
const GOOGLE_MAP_API_URL = 'https://maps.googleapis.com/maps/api/';

const styles = {
  root: {
    paddingTop: 10,
    marginLeft: 56,
  },
  banner: {
    position: 'relative',
    height: 300,
    backgroundImage: `url(${ImgToit})`,
    backgroundSize: 'cover',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'white',
      opacity: 0.8,
    },
  },
  bannerContent: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& h6': {
      marginLeft: 10,
      color: '#ff0000',
      fontWeight: 'bold'
    }
  },
  imageWrapper: {
    minHeight: 240,
  },
  image: {
    width: '100%',
  },
  heading: {
    background: '#dadada',
    color: '#ff0000',
    display: 'flex',
    alignItems: 'center',
    height: 50,
    '& > svg': {
      margin: '0 5px 0 10px'
    },
    '& > h5': {
      fontWeight: 'bold'
    }
  },
  submitBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  panopv: {
    marginBottom: 20,
  },
  probility: {},
  error: {
    color: 'red',
  },
  squareRatio: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%' /* 1:1 Aspect Ratio */
  },
  squareRatioContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  submitButtonLoader: {
    marginRight: 5
  }
};

class StreetView extends Component {
  constructor(props) {
    super(props);

    this.detectionModels = ['panopv', 'roofseg', 'poolseg', 'graffiti'];
    this.imageLayers = {};
    this.visibleImageLayers = {};
    let initialVisibleLayer, initialFetching;
    initialVisibleLayer = initialFetching = _.reduce(
      this.detectionModels,
      (acc, v) => {
        acc[v] = false;
        return acc;
      },
      {}
    );
    // TODO: translate title to translation key
    this.questionCollections = [
      {
        title: 'Aerial image',
        icon: <AerialMailIcon fontSize="large" />,
        questions: questionsAerial
      },
      {
        title: 'StreetView image',
        icon: <StreetViewIcon fontSize="large" />,
        questions: questionsStreet
      },
      {
        title: 'Other',
        icon: <BlueStartIcon fontSize="large" />,
        questions: questionsOther
      }
    ]

    this.state = {
      visibleLayer: initialVisibleLayer,
      isExisting: false,
      fetching: initialFetching,
      errors: {},
      mergedImage: null,
      values: _.merge(
        {
          place_id: null,
          place_name: null,
          street: [],
          satellite: {
            data: null,
          },
          survey: [],
        },
        _.reduce(
          this.detectionModels,
          (acc, model) => {
            acc[model] = { data: null };
            return acc;
          },
          {}
        )
      ),
      submitting: false
    };

    this.getEncodedImageFromDetectionModel = this.getEncodedImageFromDetectionModel.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeImageLayers = this.handleChangeImageLayers.bind(this);
  }

  bufferToImgSrc(buf, format) {
    if (!buf) return undefined;
    const base64Flag = 'data:image/' + format + ';base64,';
    const base64Data = buf.toString('base64');
    return base64Flag + base64Data;
  }

  handleUpdateSurvey(filter, value) {
    const filterKeys = Object.keys(filter);
    const found = !!_.find(this.state.values.survey, obj =>
      _.every(filterKeys, key => filter[key] === obj[key])
    );
    this.setState({
      values: {
        ...this.state.values,
        survey: !found
          ? [...this.state.values.survey, value]
          : _.map(this.state.values.survey, obj =>
              !_.every(filterKeys, key => filter[key] === obj[key])
                ? obj
                : value
            ),
      },
    });
  }

  async handleSelect(address) {
    const token = localStorage.getItem('TOKEN');
    try {
      const existingAddress = await axios.get(`/api/getAddress`, {
        params: {
          place_id: address.place_id,
          token,
        },
      });
      const data = _.get(existingAddress, 'data');

      // fetch satellite and street view images if they dont exist in database
      let { latitude: lat, longitude: lng, satellite, street } = data
      if (!lat || !lng) {
        const addressCoordinate = await this.getAddressCoordinate(address.place_id)
        lat = addressCoordinate.lat
        lng = addressCoordinate.lng
      }
      if (!satellite || (satellite && !satellite.data)) {
        const satelliteImage = await this.getSatelliteImage({ lat, lng })
        satellite = _.merge({}, satellite, { data: satelliteImage })
      }
      if (_.some(street, (streetView) => !streetView.data)) {
        const { streetViews } = await this.getStreetViewImages({ lat, lng })
        street = _.map(streetViews, (streetViewImage) => ({ data: streetViewImage }))
      }

      const newState = {
        isExisting: true,
        mergedImage: satellite.data ? this.bufferToImgSrc(Buffer.from(satellite.data), 'png') : null,
        values: _.merge(
          {
            ...this.state.values,
            place_id: address.place_id,
            place_name: address.description,
            street: _.map(street, img => ({ data: img.data ? Buffer.from(img.data) : null })),
            satellite: { data: satellite.data ? Buffer.from(satellite.data) : null },
            survey: data.survey,
          },
          _.reduce(
            this.detectionModels,
            (acc, model) => {
              if (data[model] && data[model].data) {
                acc[model] = {
                  data: Buffer.from(data[model].data),
                };
              }
              return acc;
            },
            {}
          )
        ),
      };
      // TODO: refactor
      this.imageLayers = { satellite: newState.mergedImage };
      this.visibleImageLayers = _.pick(this.imageLayers, 'satellite');
      this.setState(newState);
      this.detectionModels.forEach(model => {
        if (!_.get(data, model)) {
          !!newState.values.satellite.data && this.getEncodedImageFromDetectionModel(model, newState.values.satellite.data.toString('base64'));
        } else {
          this.imageLayers = _.merge(this.imageLayers, {
            [model]: this.bufferToImgSrc(_.get(newState.values[model], 'data'), 'png'),
          });
        }
      });
    } catch (err) {
      if (err.response) {
        const data = err.response.data;
        const status = err.response.status;
        if (status === 404 && _.get(data, 'error') === 'Address not found') {
          try {
            const { lat, lng } = await this.getAddressCoordinate(address.place_id)

            const { streetViews, metadata } = await this.getStreetViewImages({ lat, lng })
            this.setState({
              isExisting: false,
              values: {
                ...this.state.values,
                place_id: address.place_id,
                place_name: address.description,
                latitude: lat,
                longitude: lng,
                street: !metadata
                  ? []
                  : metadata.status === 'OK' && !!streetViews.length
                    ? _.map(streetViews, (streetView) => ({ data: Buffer.from(streetView) }))
                    : [{ error: metadata.status }],
                survey: [],
              },
            });

            const satelliteImage = await this.getSatelliteImage({ lat, lng });

            const newState = {
              ...this.state,
              mergedImage: satelliteImage ? this.bufferToImgSrc(Buffer.from(satelliteImage), 'png') : null,
              values: {
                ...this.state.values,
                satellite: { data: satelliteImage ? Buffer.from(satelliteImage) : null },
              },
            };
            this.imageLayers = { satellite: newState.mergedImage };
            this.visibleImageLayers = _.pick(this.imageLayers, 'satellite');
            this.setState(newState);
            !!newState.values.satellite.data && await Promise.all(
              this.detectionModels.map(model =>
                this.getEncodedImageFromDetectionModel(
                  model,
                  newState.values.satellite.data.toString('base64')
                )
              )
            );
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  }

  async getEncodedImageFromDetectionModel(model, base64Image) {
    const token = localStorage.getItem('TOKEN');
    this.setState({ fetching: { ...this.state.fetching, [model]: true } });
    try {
      const response = await axios.post(`/api/${model}`, { token, base64Image: base64Image });
      this.imageLayers = _.merge(this.imageLayers, { [model]: this.bufferToImgSrc(response.data, 'png') });
      if (this.state.visibleLayer[model]) {
        this.visibleImageLayers = _.merge(this.visibleImageLayers, _.pick(this.imageLayers, model));
        mergeImages(_.values(this.visibleImageLayers))
          .then(b64 => {
            this.setState({ mergedImage: b64 });
          });
      }
      this.setState({
        errors: { ...this.state.errors, [model]: false },
        values: {
          ...this.state.values,
          [model]: { data: Buffer.from(response.data, 'base64') },
        },
      });
    } catch (exception) {
      this.setState({ errors: { ...this.state.errors, [model]: exception } });
    } finally {
      this.setState({ fetching: { ...this.state.fetching, [model]: false } });
    }
  }

  async getAddressCoordinate(place_id) {
    try {
      const geocode = await geocodeByPlaceId(place_id);
      const { lat, lng } = await getLatLng(geocode[0]);
      return { lat, lng }
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  async getSatelliteImage({ lat, lng }) {
    if (!lat || !lng) return
    try {
      const response = await axios.get(
        GOOGLE_MAP_API_URL + 'staticmap',
        {
          params: {
            center: `${lat},${lng}`,
            maptype: 'satellite',
            size: '640x640',
            zoom: 20,
            scale: 2,
            format: 'png',
            key: API_KEY,
          },
          responseType: 'arraybuffer',
        }
      );
      return response.data
    } catch (e) {
      console.error(e)
      return
    }
  }

  async getStreetViewImages({ lat, lng }) {
    if (!lat || !lng) return {}
    try {
      // Google Street View Static API
      const streetViewParams = {
        size: '640x640',
        location: `${lat},${lng}`,
        source: 'outdoor',
        key: API_KEY,
      };
      const pitchs = [0, 30, 60];
      let streetViewMetadata = await axios.get(GOOGLE_MAP_API_URL + 'streetview/metadata', { params: { ...streetViewParams } });
      if (streetViewMetadata.data.status === 'OK') {
        let [streetViewResult1, streetViewResult2, streetViewResult3] = _.map(pitchs, pitch => {
          return axios.get(GOOGLE_MAP_API_URL + 'streetview', {
            params: { ...streetViewParams, pitch },
            responseType: 'arraybuffer',
          });
        });
        const [streetView1, streetView2, streetView3] = [await streetViewResult1, await streetViewResult2, await streetViewResult3];
        return { streetViews: [streetView1.data, streetView2.data, streetView3.data], metadata: streetViewMetadata.data }
      }
      return { streetViews: [], metadata: streetViewMetadata.data }
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  async handleChangeImageLayers(event) {
    const { name, checked } = event.target;
    this.setState({ visibleLayer: { ...this.state.visibleLayer, [name]: checked } });
    if (!_.isEmpty(this.imageLayers)) {
      this.visibleImageLayers = checked ? _.merge(this.visibleImageLayers, _.pick(this.imageLayers, name)) : _.omit(this.visibleImageLayers, name);
      mergeImages(_.values(this.visibleImageLayers))
        .then(b64 => {
          this.setState({ mergedImage: b64 });
        });
    }
  }

  async handleSubmit() {
    const token = localStorage.getItem('TOKEN');
    const body = {
      addressData: _.omit(this.state.values, [...this.detectionModels]),
      token,
    };
    this.setState({ submitting: true })
    try {
      if (!this.state.isExisting) {
        await axios.post(`/api/createAddress`, body);
        toast.success('Address has been saved successfully')
        this.setState({ isExisting: true })
      } else {
        await axios.put(`/api/updateAddress`, body);
        toast.success('Address has been updated successfully')
      }
    } catch (e) {
      console.error(e)
      toast.error('An error has occured, please try again!')
    } finally {
      this.setState({ submitting: false })
    }
  }

  render_question(question, index) {
    const { label, question_type } = question;
    const _question = _.find(
      this.state.values.survey,
      obj => obj.question === label
    );
    if (question_type === 'radio') {
      return (
        <RadioButton
          key={index}
          label={label}
          name={label}
          onChange={event => {
            this.handleUpdateSurvey(
              { question_type, question: label },
              {
                question_type,
                question: label,
                answer: _.toNumber(event.target.value),
              }
            );
          }}
          value={!!_question ? _.toString(_.get(_question, 'answer')) : null}
        />
      );
    }
    if (question_type === 'rating') {
      return (
        <RatingComponent
          key={index}
          label={label}
          name={label}
          onChange={(e, value) => {
            this.handleUpdateSurvey(
              { question_type, question: label },
              { question_type, question: label, answer: value }
            );
          }}
          size="large"
          value={_.get(_question, 'answer', 0)}
        />
      );
    }
    if (question_type === 'slider') {
      return (
        <SliderInput
          key={index}
          label={label}
          name={label}
          onChange={(e, value) => {
            this.handleUpdateSurvey(
              { question_type, question: label },
              { question_type, question: label, answer: value }
            );
          }}
          value={_.get(_question, 'answer', 0)}
          min={0}
          max={10}
          step={1}
        />
      );
    }
    if (question_type === 'edit') {
      return (
        <EditBox
          key={index}
          label={label}
          name={label}
          onChange={(e, value) => {
            this.handleUpdateSurvey(
              { question_type, question: label },
              { question_type, question: label, answer: value }
            );
          }}
          value={_.toString(_.get(_question, 'answer'))}
        />
      );
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.banner}>
                <Container className={classes.bannerContent} maxWidth="sm">
                  <Grid container alignItems="center" justify="center" spacing={3}>
                    {/** TODO: translate plain text to translation key */}
                    <Grid item xs={12}><Typography align="center" variant="h3">Search</Typography></Grid>
                    <Grid item xs={12}>
                      <GooglePlacesAutocomplete
                        inputStyle={{
                          display: 'flex',
                          width: '100%',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          borderRadius: '14px',
                        }}
                        onSelect={this.handleSelect}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <Grid container justify="center">
                            <SatelliteIcon fontSize="large" />
                            {/** TODO: translate plain text to translation key */}
                            <Typography variant="h6">Satellite image</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} container justify="center">
                          <FormGroup row>
                            {this.detectionModels.map(model => (
                              <FormControlLabel
                                key={model}
                                checked={this.state.visibleLayer[model]}
                                control={
                                  <Checkbox
                                    name={model}
                                    onChange={this.handleChangeImageLayers}
                                  />
                                }
                                label={model}
                              />
                            ))}
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Paper>
            </Grid>
            {_.some(this.state.errors, (error) => !!error) && !!this.state.mergedImage && (
              <Grid item xs={12}>
                <Alert icon={false} severity="error">
                  {`Failed to get ${_.keys(_.pickBy(this.state.errors, (error) => !!error)).join(', ')} result(s). `}
                  <Button
                    variant="outlined"
                    onClick={() => {
                      _.forEach(_.pickBy(this.state.errors, (error) => !!error), (v, model) => {
                        this.getEncodedImageFromDetectionModel(model, this.state.values.satellite.data.toString('base64'))
                      })
                    }}
                  >
                    Retry
                  </Button>
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Paper className={classes.squareRatio} variant="outlined">
                        <Grid className={classes.squareRatioContent} container direction="column" justify="center" alignItems="center">
                          {!this.state.mergedImage && (
                            <>
                              <img src={NoImages} width="100px" />
                              <Typography variant="caption">Satellite image</Typography>
                            </>
                          )}
                          {_.some(this.detectionModels, model => !!this.state.fetching[model]) && <CircularProgress size={20} />}
                          {_.every(this.detectionModels, model => !this.state.fetching[model]) && !!this.state.mergedImage && (
                            <img
                              className={classes.image}
                              src={this.state.mergedImage}
                            />
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify="center" spacing={1}>
                        {!!this.state.values.street.length
                          ? _.map(this.state.values.street, (item, index) => {
                              return 'data' in item ? (
                                <Grid key={`street-image-${index}`} item xs={4}>
                                  <Paper>
                                    <img
                                      className={classes.image}
                                      src={this.bufferToImgSrc(item.data, 'png')}
                                    />
                                  </Paper>
                                </Grid>
                              ) : ('No image for StreetView (Error: "' + item.error + '")')
                            })
                          : _.map([0, 1, 2], value => (
                              <Grid key={value} item xs={4}>
                                <Paper className={classes.squareRatio} variant="outlined">
                                  <Grid className={classes.squareRatioContent} container direction="column" justify="center" alignItems="center">
                                    <img src={NoImages} width="50px" />
                                    <Typography variant="caption">Streetview image</Typography>
                                  </Grid>
                                </Paper>
                              </Grid>
                            ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    {this.questionCollections.map(({ icon, title, questions }) => (
                      <Grid key={title} item xs={12}>
                        <div className={classes.heading}>
                          {icon}
                          <Typography variant="h5">{title}</Typography>
                        </div>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            {_.map(questions, (question, index) => {
                              return (
                                <Grid item key={index} xs={12}>
                                  {this.render_question(question)}
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <div className={classes.submitBtn}>
                    <Button
                      color="primary"
                      onClick={this.handleSubmit}
                      disabled={!this.state.values.place_id || this.state.submitting}
                      disableElevation
                      variant="contained"
                    >
                      {/** TODO: translate plain text to translation key */}
                      {this.state.submitting && <CircularProgress className={classes.submitButtonLoader} size={20} />}
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <ToastContainer autoClose={3000} />
      </div>
    );
  }
}

export default withStyles(styles)(StreetView);
