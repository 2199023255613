import React, { Component, SvgIcon , useCallback} from 'react';
import { BrowserRouter as Router, Link, withRouter } from 'react-router-dom';
import { Sidenav, Nav, Icon, Button } from 'rsuite';
import Svg from '../components/Svg';

const Logout = (props) => {
  const logout = useCallback(() => {
    localStorage.removeItem('TOKEN')
    this.props.history.push('/')
  })
  return <Button onPress={() => logout()}></Button>
}
const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
//SIDEBAR COMPONENT 

class SideBar extends Component {
    constructor (props) {
        super(props);
        this.logOut = this.logOut.bind(this);
    }

  logOut() // need to implement this method to logout
  {
    localStorage.clear()
    this.props.history.push('/')
  }
  
  render() {
    const { role } = this.props
    if(this.props.location.pathname === '/')
      return(null)
    else if (width < 600)
      return null
    else {
      return (
        <div style={{position: 'fixed', height: '100%', zIndex: 5}}>
          <Sidenav activeKey="1" appearance='inverse' expanded={false} style={{height: '100%'}}>
            <Sidenav.Header>
              <div style={{display : 'flex', justifyContent : 'center'}}>
                <Svg style={{margin : 10}}/>
              </div>
            </Sidenav.Header>
            <Sidenav.Body>
              {!!role && role.toLowerCase() === 'streetview' ? (
                <Nav>
                  <Nav.Item eventKey="8" icon={<Icon icon="street-view" />} componentClass={Link} to= "/streetview">
                    Street View
                  </Nav.Item>
                  <Nav.Item eventKey="7" icon={<Icon icon="off"/>} componentClass={Link} to="/"> {/* not a good practice because we delete the token on componentdidmount and not here but the onSelect prop doesnt work */}
                    Log Out
                  </Nav.Item>
                </Nav>
              ) : (
                <Nav >
                  <Nav.Item eventKey="2" icon={<Icon icon="map" />} componentClass={Link} to='/alerts'>
                    Alerts Visualisation
                  </Nav.Item>
                  <Nav.Item eventKey="3" icon={<Icon icon="map" />} componentClass={Link} to="/threshold">
                    Threshold Management
                  </Nav.Item>
                  <Nav.Item eventKey="4" icon={<Icon icon="exclamation-triangle" />} componentClass={Link} to="/table">
                    Alerts Table
                  </Nav.Item>
                  <Nav.Item eventKey="5" icon={<Icon icon="plus-square" />} componentClass={Link} to="/editor">
                    Alerts Editor
                  </Nav.Item>
                  {this.props.admin !== true ? null : (
                    <Nav.Item eventKey="6" icon={<Icon icon="user" />} componentClass={Link} to="/admin">
                      Admin
                    </Nav.Item>
                  )}
                  {this.props.admin !== true ? null : (
                    <Nav.Item eventKey="8" icon={<Icon icon="street-view" />} componentClass={Link} to= "/streetview">
                      Street View
                    </Nav.Item>
                  )}
                  <Nav.Item eventKey="7" icon={<Icon icon="off"/>} onClick={this.logOut}> {/* not a good practice because we delete the token on componentdidmount and not here but the onSelect prop doesnt work */}
                    Log Out
                  </Nav.Item>
                </Nav>
              )}
              </Sidenav.Body>
          </Sidenav>
        </div>
      );
    }
  }
}

export default withRouter(SideBar);
