import React from 'react';
import { Typography, Button, withStyles, styled, Link } from '@material-ui/core';
import { Input, InputGroup, Icon } from 'rsuite';
import logo from '../assets/Group2.png';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';

const ButtonBlue = withStyles({ // custom button blue
    root: {
        '&:hover': {
            backgroundColor: '#164c75'
        },
        "backgroundColor": '#1C5D90',
        "color": '#fff'
    }
})(Button);

class Admin extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            userName: '',
            error: '',
            success: '',
            role: ''
        };
        this.initialState = Object.assign({}, this.state);
        this.handlechange = this.handlechange.bind(this);
        this.addUser = this.addUser.bind(this);
    }

    validateEmail (email) // regex to match email
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    addUser () // method to add user in database
    {
        const check = this.handleError(); //check error in add user form
        if (check !== 1) {
            this.setState({ error: check, success: '' });
            return;
        }

        const body = {
            email: this.state.email,
            password: this.state.password,
            userName: this.state.userName,
            role: this.state.role,
            token: localStorage.getItem('TOKEN')
        };

        Axios.post(`/api/addUser`, body)
            .then(data => {
                if (data.status === 200) {
                    this.setState({
                        email: '',
                        password: '',
                        userName: '',
                        error: '',
                        role: '',
                        success: 'New user added !'
                    });
                }
            })
            .catch(err => {
                if (err.response.status === 400) {this.props.history.push({ pathname: '/' });}
                console.log(err);
                this.setState({ error: err.response.data.error ? err.response.data.error : 'Error', success: '' });
            });
    }

    handleError () {
        const { email, userName, password } = this.state;
        let error = '';

        if (!email || !userName || !password) {error = 'You need to fill all the fields !';} else if (!this.validateEmail(email)) {error = 'Wrong email format !';} else if (userName.length < 5) {error = 'Username must be 5 characters at least !';}
        return error ? error : 1;
    }

    handlechange (text, type) // OnChange for all input
    {
        this.setState({
            [type]: text
        });
    }

    render () {
        return (
            <div style={{ position: "absolute", width: '-webkit-fill-available', height: '100%', flexDirection: 'column', display: 'flex', marginLeft: '56px', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: 'rgb(19, 63, 97)', zIndex: 2 }}>
                <div className="admin-form-container">
                    <div className={'admin-form'}>
                        <div className={'form-logo'}>
                            <img src={logo}/>
                        </div>
                        <div className="login-form-group">
                            <InputGroup inside style={{ width: '300px', marginBottom: 10 }}>
                                <InputGroup.Addon>
                                    <Icon icon="avatar" />
                                </InputGroup.Addon>
                                <Input placeholder="Email" value={this.state.email} onChange={e => this.handlechange(e, 'email')}/>
                            </InputGroup>
                            <InputGroup inside style={{ width: '300px', marginBottom: 10, color: '#1C5D90' }}>
                                <InputGroup.Addon>
                                    <Icon icon="avatar" />
                                </InputGroup.Addon>
                                <Input value={this.state.userName} placeholder="Username" type="text" onChange={e => this.handlechange(e, 'userName')}/>
                            </InputGroup>
                            <InputGroup inside style={{ width: '300px', marginBottom: 10, color: '#1C5D90' }}>
                                <InputGroup.Addon>
                                    <Icon icon="avatar" />
                                </InputGroup.Addon>
                                <Input value={this.state.password} placeholder="Password" type="text" onChange={e => this.handlechange(e, 'password')}/>
                            </InputGroup>
                            <InputGroup inside style={{ width: '300px', marginBottom: 10, color: '#1C5D90' }}>
                                <InputGroup.Addon>
                                    <Icon icon="avatar" />
                                </InputGroup.Addon>
                                <Input value={this.state.role} placeholder="Role" type="text" onChange={e => this.handlechange(e, 'role')}/>
                            </InputGroup>
                        </div>
                        {
                            this.state.success ? <Typography color="primary" >{this.state.success}</Typography> : <Typography color="error" >{this.state.error}</Typography>
                        }
                        <div style={{ marginTop: 30, width: '90%' }}>
                            <ButtonBlue style={{ width: '-webkit-fill-available' }} onClick={this.addUser}>
                    Add User
                            </ButtonBlue>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Admin);
