import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';

const labels = {
	0.5: 'Useless',
	1: 'Useless+',
	1.5: 'Poor',
	2: 'Poor+',
	2.5: 'Ok',
	3: 'Ok+',
	3.5: 'Good',
	4: 'Good+',
	4.5: 'Excellent',
	5: 'Excellent+'
};
const useStyles = makeStyles({
	rating: {
		width: 150,
		marginTop: 10,
		marginBottom: 10
	}
});

const RatingComponent = props => {
	const [ hover, setHover ] = React.useState(-1)
	const { label, name, onChange, size, value } = props
	const classes = useStyles()
	return (
		<Grid container justify="space-between" alignItems="center">
			<FormLabel component="legend">{label}</FormLabel>
			<div className={classes.rating}>
				<Rating
					size={size}
					name={name}
					value={value}
					precision={0.5}
					onChange={onChange}
					onChangeActive={(event, newHover) => {
						setHover(newHover)
					}}
				/>
			</div>
		</Grid>
	)
}

export default RatingComponent
