import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

let mainblue = '#1C5D90';
const ColorRadio = withStyles({
  root: {
    // color: mainblue,
    '&$checked': {
      color: mainblue,
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const RadioButton = props => {
  const { label, onChange, value, name } = props;
  return (
    <Grid container justify="space-between" alignItems="center">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        row
        aria-label="option"
        name={name}
        value={value}
        onChange={onChange}
      >
        <FormControlLabel
          value="1"
          control={<ColorRadio checked={value === '1'} color="primary" />}
          label="Yes"
        />
        <FormControlLabel
          value="0"
          control={<ColorRadio checked={value === '0'} />}
          color="primary"
          label="No"
        />
      </RadioGroup>
    </Grid>
  );
};

RadioButton.propTypes = {
  value: PropTypes.oneOf(['0', '1', null]),
};

export default RadioButton;
