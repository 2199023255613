import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BoxPlot } from '@vx/stats';
import { scaleBand, scaleLinear } from '@vx/scale';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { Group } from '@vx/group';
import { LegendOrdinal } from '@vx/legend';
import { timeFormat } from 'd3-time-format';
import { makeStyles } from '@material-ui/core/styles'

const margin = {
  left: 60,
  bottom: 40,
  right: 40,
  top: 80,
};

const useStyles = makeStyles({
  root: {
    position: 'relative'
  },
  legend: {
    textAlign: 'center'
  }
})

// accessors
const x = d => moment.utc(d.date).toDate();
const max =  d => d.max;
const min = d => d.min;
const median = d => d.avg;
const iqr = d => d.std;

const AlertBoxPlot = props => {
  const {
    data,
    dataKey,
    width,
    height,
    verticalAxisUnit
  } = props;

  const classes = useStyles(props)

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = scaleBand({
    rangeRound: [0, xMax],
    domain: _.map(data, x),
    paddingInner: 1,
    paddingOuter: 0.5
  });

  const yScale = scaleLinear({
    rangeRound: [yMax, 0],
    domain: [Math.floor(Math.min(..._.map(data, min))), Math.max(..._.map(data, max))]
  });

  const boxWidth = Math.max(6, Math.floor(40 / data.length))

  return (
    <div className={classes.root}>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill='#fff' rx={14} />
        <Group top={margin.top} left={margin.left}>
          {_.map(data, (d) => (
            <BoxPlot
              key={`${dataKey}_${x(d)}`}
              data={d}
              max={max(d)}
              min={min(d)}
              left={xScale(x(d)) - boxWidth / 2}
              firstQuartile={median(d) - iqr(d)/2}
              thirdQuartile={median(d) + iqr(d)/2}
              median={median(d)}
              boxWidth={boxWidth}
              fill="#00FFFF"
              fillOpacity={0.3}
              stroke="#000"
              strokeWidth={1}
              valueScale={yScale}
            />
          ))}
          <AxisLeft
            scale={yScale}
            tickFormat={v => `${v}${verticalAxisUnit || ''}`}
          />
          <AxisBottom
            scale={xScale}
            // numTicks={4}
            tickFormat={date => timeFormat('%d/%m %Hh')(date)}
            tickValues={_.map(_.range(4), v => _.map(data, x)[v*(Math.floor(data.length/4))])}
            top={yMax}
          />
        </Group>
      </svg>
      <div className={classes.legend}>
        {dataKey}
      </div>
    </div>
  )
}

AlertBoxPlot.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  verticalAxisUnit: PropTypes.string
}

AlertBoxPlot.defaultProps = {
  width: 800,
  height: 480,
};

export default AlertBoxPlot;
