import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'

import AlertVisualisationDetail from '../AlertDetail/Visualisation'
import Button from '@material-ui/core/Button'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'

import LogoCaniculeFill from '../../assets/icon-front/haute-temperature-plein.png'
import LogoRainFill from '../../assets/icon-front/grosse-pluie-plein.png'
import LogoWindFill from '../../assets/icon-front/vent-plein.png'
import LogoExtremeColdFill from '../../assets/icon-front/bas-temperature-plein.png'
import LogoColdThunderFill from '../../assets/icon-front/orage-plein.png'
import LogoSnowFill from '../../assets/icon-front/neige-plein.png'
import LogoIceFill from '../../assets/icon-front/verglas-plein.png'
import LogogSubFill from '../../assets/icon-front/icon-vague-plein.png'
import LogoPollutionFill from '../../assets/icon-front/pollution-fondbleu.png';
import LogoCrueFill from '../../assets/icon-front/inondation-fondbleu.png'

//BAO'S CODE

const SORTED_SIMPLEPROPS = [
  'id',
  'level',
  'previous_level',
  'is_new',
  'level_changed',
  'postal_code',
  'risk_duration',
  'expected_at',
  'estimated_end'
]

const arrayLogo = {
  WINDS: LogoWindFill,
  COLD_WAVE: LogoExtremeColdFill,
  HEAT_WAVE: LogoCaniculeFill,
  THUNDER: LogoColdThunderFill,
  THUNDER_V2: LogoColdThunderFill,
  THUNDER_V3: LogoColdThunderFill,
  SNOW: LogoSnowFill,
  ICE: LogoIceFill,
  HEAVY_RAIN: LogoRainFill,
  SUBMERSION : LogogSubFill,
  FLOOD : LogoCrueFill,
  POLLUTION : LogoPollutionFill
}

const ExpansionPanel = withStyles({
  root: {
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 48,
    '&$expanded': {
      minHeight: 48
    }
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles({
  root: {
    flexDirection: 'column'
  }
})(MuiExpansionPanelDetails)

const AlertAccordion = props => {
  const {
    data,
    expanded,
    format,
    omitAttrs,
    onExpand
  } = props

  const [chartOpen, setChartOpen] = React.useState(false)
  const { t } = useTranslation()

  const type = _.get(data, 'type')
  let omittedData = _.omit(data, omitAttrs)
  let sortedData = _.reduce(SORTED_SIMPLEPROPS, (acc, keyValue) => {
    if (!acc.hasOwnProperty(keyValue))
      acc[keyValue] = _.get(omittedData, keyValue)
    return acc
  }, {})
  let extraAttrs = _.filter(Object.keys(omittedData), key => !SORTED_SIMPLEPROPS.includes(key))
  let renderedData = _.reduce(extraAttrs, (acc, keyValue) => {
    if (!acc.hasOwnProperty(keyValue))
      acc[keyValue] = _.get(omittedData, keyValue)
    return acc
  }, sortedData)

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={onExpand}
    >
      <ExpansionPanelSummary>
        <Typography variant="h6">{type}</Typography>
        <img src={arrayLogo[type]} width='35' height='35'/>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {_.map(renderedData, (value, key) => (
          <Typography key={key} gutterBottom>{format(value, key)}</Typography>
        ))}
        <Button onClick={() => setChartOpen(true)} variant='contained'>
          {t('button.detail')}
        </Button>
        <AlertVisualisationDetail
          alertDetail={data}
          open={chartOpen}
          onClose={() => setChartOpen(false)}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

AlertAccordion.propTypes = {
  data: PropTypes.object,
  format: PropTypes.func,
  omitAttrs: PropTypes.array
}

export default AlertAccordion
