import  React, { Component } from "react";
import { Typography, Divider} from '@material-ui/core';
import LogoCanicule2 from '../assets/icon-front/haute-temperature-plein.png';
import LogoExtremeCold2 from '../assets/icon-front/bas-temperature-plein.png';
import LogoWind2 from '../assets/icon-front/vent-plein.png';

export default class Content extends Component{
    constructor(props)
    {
        super(props)
    }

    render(){
        return(
            <div style={{backgroundColor : ' rgba(255,255,255,0.91)',  borderRadius : '15px', boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px'}}>
                <div style={{float : 'left'}}>
                  <img className="accordion_image" src={LogoCanicule2} width={'50px'} height={'50px'}/>
                </div>
                {
                  this.props.state.query ? 
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center', float: 'right', paddingLeft : '10px'}}>
                  <Typography>{this.props.state.currentCanicule.min ? 'Min : ' + Math.round(this.props.state.currentCanicule.min) : 'Min : 0'}</Typography>
                  <Typography>{this.props.state.currentCanicule.max ? 'Max : ' + Math.round(this.props.state.currentCanicule.max) : 'Max : 0'}</Typography>
                </div>
                :
                null
                }
            </div>
            <Divider/>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px'}}>
            <div style={{float : 'left'}}>
              <img className="accordion_image" style={{left: 0}} src={LogoExtremeCold2}  width={'50px'} height={'50px'}/>
            </div>
             {
                  this.props.state.query ? 
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center', float: 'right', paddingLeft : '10px'}}>
                  <Typography>{this.props.state.currentExtremeCold.min ? 'Min : ' + Math.round(this.props.state.currentExtremeCold.min) : 'Min : 0'}</Typography>
                  <Typography>{this.props.state.currentExtremeCold.max ? 'Max : ' + Math.round(this.props.state.currentExtremeCold.max) : 'Max : 0'}</Typography>
                </div>
                :
                null
              }
            </div>
            <Divider/>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px'}}>
            <div style={{float : 'left'}}>
              <img className="accordion_image" style={{left: 0}} src={LogoWind2}  width={'50px'} height={'50px'}/>
            </div>
              {
                this.props.state.query ? 
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center', float: 'right', paddingLeft : '10px'}}>
                <Typography>{this.props.state.currentWind.level1 ? 'Yellow : ' + Math.round(this.props.state.currentWind.level1) : 'Yellow : 0'}</Typography>
                <Typography>{this.props.state.currentWind.level2 ? 'Orange : ' + Math.round(this.props.state.currentWind.level2) : 'Orange : 0'}</Typography>
                <Typography>{this.props.state.currentWind.level3 ? 'Red : ' + Math.round(this.props.state.currentWind.level3) : 'Red : 0'}</Typography>
              </div>
              :
              null
              }
            </div>
          </div>
        )
    }
}