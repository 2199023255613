import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Threshold from './container/ThresholdMap';
import ColorMap from './container/Map';
import 'react-toastify/dist/ReactToastify.min.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
// Be sure to include styles at some point, probably during your bootstraping
import './rsuite-default.css';
import './react-datepicker.css';
import './bootstrap.min.css';
import './App.css';
import Login from './components/Login';
import Table from './components/Table';
import Editor from './container/Editor';
import Admin from './components/Admin';
import StreetView from "./container/StreetView";
import withAuth from './components/WithAuth';
import './i18n'

class App extends Component {
    constructor (props) {
        super(props);
        this.state = {
            admin: false
        };
    }

    render () {
        return (
            <div style={{ height: '100%' }}>
                <Router >
                    <Switch>
                        <Route exact path="/alerts" component={withAuth(ColorMap)} />
                        <Route exact path="/threshold" component={withAuth(Threshold)} />
                        <Route exact path="/table" component={withAuth(Table)}/>
                        <Route exact path="/editor" component={withAuth(Editor)} />
                        <Route exact path="/admin" component={withAuth(Admin)} />
                        <Route exact path="/streetview" component={withAuth(StreetView)} />
                        <Route exact path="/" component={Login} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;

ReactDOM.render(<App />, document.getElementById('root'));
