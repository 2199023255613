module.exports = {
  'm/s': [
    'wind',
    'wind_raf',
    'wind_speed_500mb',
    'wind_speed_850mb',
    'wind_direction_500mb',
    'wind_direction_850mb'
  ],
  '°C': [
    'temperature',
    'temperature_500mb',
    'temperature_850mb',
    'dew_point',
    'dew_point_temperature_850mb',
    'max_day_1',
    'max_day_2',
    'max_day_3',
    'min_day_1',
    'min_day_2',
    'min_day_3',
    'el_temperature'
  ],
  'cm': [
    'snow_precipitation_low',
    'snow_precipitation_high'
  ],
  'm': [
    'total_height',
    'swell_height',
    'wave_height'
  ],
  '%': [
    'nebul'
  ],
  'mm/m2': [
    'cumul',
    'value_v2.*'
  ],
  'j/kg': [
    'mucape'
  ],
  'µg/m³': [
    'avg',
    'max'
  ]
}
