import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles({
  root: {
    width: 250,
  },
});

const EditBox = props => {
  const { label, name, onChange, value } = props
  const [ hover, setHover ] = React.useState("toto")

  const onChangeInput = event => {
    onChange(event, event.target.value);
    setHover(event.target.value);
  };

  return (
    <div>
      <FormLabel component="legend">{label}</FormLabel>
      <div className={useStyles.root}>
        <Input
          fullWidth
          value={value}
          onChange={onChangeInput}
        />
      </div>
    </div>
  )
}

export default EditBox
