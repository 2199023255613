//MAP LAYERS FOR THE THRESHOLD MAP

const source = 'carte';
const layout = {
  'line-join': 'round',
  'line-cap': 'round',
};

export const fillLayer = {
  id: 'carte',
  type: 'fill',
  source: source,
  layout: {},
  paint: {
    'fill-color': 'rgba(33,150,243,1)',
    'fill-opacity': [
      'case', ['boolean', ['feature-state', 'hover'], false],
      0.3,
      0.15
    ],
  },
};

export const strokeLayer = {
  id: 'carte-stroke',
  type: 'line',
  source: source,
  layout: layout,
  paint: {
    'line-width': {
      stops: [[0, 2], [22, 4]],
    },
    'line-color': '#90CAF9',
  },
};
