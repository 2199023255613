import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { returnColor } from '../Convert'

import AlertAccordionItem from '../AlertAccordionItem'

//BAO 'S CODE 

const AlertAccordionList = props => {
  const { className, disableCollapse, items, style } = props
  const sortedItems = _.sortBy(items, item => _.get(item, 'expected_at'))

  const [currentAlert, setCurrentAlert] = useState(_.get(_.first(sortedItems), 'id'))

  const handleExpand = (alertId) => (e, isExpanded) => setCurrentAlert(isExpanded ? alertId : false)

  useEffect(() => {
    setCurrentAlert(_.get(_.first(sortedItems), 'id'))
  }, [items])

  return (
    <div className={className} style={style}>
      {_.map(sortedItems, item => {
        const itemId = _.get(item, 'id')
        return (
          <AlertAccordionItem
            key={itemId}
            data={item}
            expanded={disableCollapse ? true : itemId === currentAlert}
            format={(value, key) => {
              switch(key) {
                case 'polluants' :
                  null
                case 'expected_at':
                  return `at : ${moment.utc(value).local().format('YYYY/MM/DD HH:00').toString()}`
                case 'estimated_end':
                  return `end : ${moment.utc(value).local().format('YYYY/MM/DD HH:00').toString()}`
                case 'is_new':
                  return `is new: ${value}`
                case 'risk_duration':
                  return `risk duration : ${value} h`
                case 'level':
                  return (
                    <React.Fragment>
                      {'level :'}
                      <span style={{ display: 'inline-block', height : 10, width : 50, backgroundColor : returnColor(value), alignSelf: 'center', marginLeft : 10, borderRadius : '14px'}}></span>
                    </React.Fragment>
                  )
                case 'previous_level':
                  return (
                    <React.Fragment>
                      {'previous level :'}
                      <span style={{display: 'inline-block', height : 10, width : 50, backgroundColor : returnColor(value), alignSelf: 'center', marginLeft : 10, borderRadius : '14px'}}></span>
                    </React.Fragment>
                  )
                case 'rain':
                case 'sweat':
                  return `${key} : ${Math.round(value)} mm`
                case 'temperatures_max_heatwave':
                case 'temperatures_min_coldfront':
                  return `temperature min : ${parseFloat(value).toFixed(1)} °C`
                case 'temperatures_max_coldfront':
                case 'temperatures_min_heatwave':
                  return `temperature max : ${parseFloat(value).toFixed(1)} °C`
                case 'snow_precipitation_high':
                case 'snow_precipitation_low':
                  return `${key} : ${parseFloat(value).toFixed(1)} cm`
                case 'frost_temperature':
                case 'frost_temperature_dew':
                case 'thunder_el':
                  return `${key} : ${parseFloat(value).toFixed(1)} °C`
                case 'thunder_wind':
                  return `${key} : ${parseFloat(value).toFixed(1)} m/s`
                case 'thunder_winds_altitude':
                case 'thunder_winds_ground':
                case 'high_wind_average':
                case 'high_wind_gust':
                  return `${key} : ${parseFloat(value).toFixed(1)} km/h`
                case 'thunder_mucape':
                  return `${key} : ${parseFloat(value).toFixed(1)} j/kg`
                default:
                  return `${key}: ${value}`
              }
            }}
            omitAttrs={['_id', 'created_at', 'temperatures', 'type']}
            onExpand={!disableCollapse ? handleExpand(itemId) : undefined}
          />
        )
      })}
    </div>
  )
}

AlertAccordionList.propTypes = {
  className: PropTypes.string,
  disableCollapse: PropTypes.bool,
  items: PropTypes.array,
  style: PropTypes.object
}

AlertAccordionList.defaultProps = {
  disableCollapse: false,
  items: []
}

export default AlertAccordionList
