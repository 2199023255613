import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles({
  root: {
    width: 250,
  },
  input: {
    width: 42,
  },
});

const SliderInput = props => {
  const { label, name, onChange, value, min, max, step } = props
  const [ hover, setHover ] = React.useState(min)

  const onChangeSlider = (event, newValue) => {
    onChange(event, newValue);
    setHover(newValue);
  };

  const onChangeInput = event => {
    var newValue = Number(event.target.value)
    event.target.value = newValue
    if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    }
    onChange(event, newValue);
    setHover(newValue);
  };

  return (
    <div style={{marginTop: 10}}>
      <FormLabel component="legend">{label}</FormLabel>
      <div className={useStyles.root}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Slider
              value={value}
              min={min}
              max={max}
              step={step}
              valueLabelDisplay="auto"
              onChange={onChangeSlider}
            />
          </Grid>
          <Grid item>
            <Input
              className={useStyles.input}
              value={value}
              min={min}
              max={max}
              step={step}
              onChange={onChangeInput}
              inputProps={{
                type: 'number',
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default SliderInput
