import _ from 'lodash';
import React from 'react';
import {convert, Calendar} from './Convert';
import Settings from '@material-ui/icons/Settings';
import Delete from '@material-ui/icons/Delete';
import Archive from '@material-ui/icons/Archive';
import { Typography , IconButton} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import { withStyles } from '@material-ui/core';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MUISnackbarContent from '@material-ui/core/SnackbarContent';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Axios from 'axios';
import moment  from 'moment';
import { CSVLink } from "react-csv";
import Loading from './Loading';
import { AlertVisualisationDetail } from './AlertDetail'

const environment = process.env.NODE_ENV || 'development'
const config = require(`../../config/${environment}`)
const today = new Date()
const tomo = convert(today.setDate(today.getDate() + 3))


const SnackbarContent = withStyles({
  root : {
    backgroundColor: '#d32f2f'
  },
  message: {
    maxWidth: '500px'
  }
})(MUISnackbarContent)

const TypoWhite = withStyles({
  root : {
    color : '#fff',
  }
})(Typography)

const headersData = [
  { label: "Type", key: "type" },
  { label: "Level", key: "level" },
  { label: "Previous level", key: "previous_level" },
  { label: "Expected at", key: "expected_at" },
  { label: "Estimated end", key: "estimated_end" },
  { label: "Risk duration", key: "risk_duration" },
  { label: "Postal code", key: "postal_code" },
  { label: "Heatwave min", key: "temperatures_min_heatwave" },
  { label: "Heatwave max", key: "temperatures_max_heatwave" },
  { label: "Thunder wind alt", key: "thunder_winds_altitude" },
  { label: "Thunder wind ground", key: "thunder_winds_ground" },
  { label: "High wind avg'", key: "high_wind_average" },
  { label: "High wind gust", key: "high_wind_gust" },
  { label: "frost temperature", key: "frost_temperature" },
  { label: "snow low", key: "snow_precipitation_low" },
  { label: "snow high", key: "snow_precipitation_high" },
  { label: "rain", key: "rain" },
  { label: "is new", key: "is_new" },
  { label: "level changed", key: "level_changed" },
];
 export default class Demo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows : [],
      dataToDownload: [],
      columns: defaultColumnWidths,
      startDate : new Date(),
      current: '',
      loading : true,
      realStartDate : convert(new Date()),
      endDate : new Date(tomo),
      realEndDate : convert(tomo),
      height : window.innerHeight,
      isShowSnack: false,
      selectedRowInfo: {}
    }
    this.reactTable = React.createRef();
    this.onClickAlert = this.onClickAlert.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
    this.onTableViewChange = this.onTableViewChange.bind(this);
    this.handleCloseSnack = this.handleCloseSnack.bind(this);
    this._getTdProps = this._getTdProps.bind(this);
    this._getTrProps = this._getTrProps.bind(this);
  }

  // method to get alert from api
  async getAlert(startDate, endDate) {
    const token = localStorage.getItem('TOKEN')
    const { data } = await Axios.post('/api/getAlert', { token, startDate, endDate })

    this.setState({
      rows : data,
      dataToDownload : data,
      loading : false
    })
  }

  componentDidMount(){
    this.getAlert(this.state.realStartDate, this.state.realEndDate) // get alert when component is mount
  }

  componentDidUpdate(prevProps, prevState) // get alert if startdate or endate is changing
  {
      if(this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate)
      {
        this.setState({loading : true})
        this.getAlert(this.state.realStartDate, this.state.realEndDate)
      }
  }

  handleChangeStart(e){ // onchange startdate
    const diffDays = moment().diff(e,'days')
    const { admin } = this.props
    if (diffDays <= 45 || !!admin) {
      this.setState({
        startDate : e,
        realStartDate : convert(e),
        isShowSnack: false
      })
    } else {
      this.setState({
        isShowSnack : true
      })
    }
  }

  handleCloseSnack(){
    this.setState({
      isShowSnack : false
    })
  }

  handleChangeEnd(e){
      this.setState({
          endDate : e,
          realEndDate : convert(e)
      })
  }

  onClickAlert(row) // on click modify
  {
    const alert = this.state.rows[row.index]
    delete alert._id
    this.props.history.push({
      pathname: '/editor',
      alert: alert
    })
  }

  onTableViewChange() {
    const current = this.reactTable.current;
    if (!!current) {
      const data = _.get(current, 'state.sortedData')
      this.setState({ dataToDownload: data })
    }
  }

  deleteAlert(row) // on click delete
  {
    const _id = this.state.rows[row.index].id
    Axios.post('/api/alert/manage?operation=DELETE', {id : _id})
              .then(data => {
                this.getAlert(this.state.realStartDate, this.state.realEndDate)
              })
              .catch(err => {
                  console.log(err)
              })
  }

  _getTdProps(state, rowInfo, column, instance) {
    return {
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'normal'
      }
    }
  }

  _getTrProps(state, rowInfo, column, instance) {
    if (rowInfo === undefined) return {}
    const isSelected = rowInfo.original.id === this.state.selectedRowInfo.id
    const style = {
      cursor: 'pointer'
    }
    if (isSelected) style.backgroundColor = 'rgba(0,0,0,0.3)'
    return {
      onClick: (e, handleOriginal) => {
        if (handleOriginal) {
          handleOriginal()
        }
        this.setState({
          selectedRowInfo: rowInfo.original,
          openVisualisation: true
        })
      },
      style
    }
  }

  render(){
    if(this.state.loading)
      return <Loading/>
    return (
      <div>
        <div style={{position: "absolute", width: '-webkit-fill-available',height: '100%',flexDirection: 'column', display: 'flex', marginLeft: '56px', justifyContent: 'space-evenly', alignItems: 'center',backgroundColor: 'rgb(19, 63, 97)', zIndex: 2}}>
          <div style={{width: '95%', display: 'flex', }}>
            <div style={{display: 'flex', flexDirection: 'column',}}>
              <div style={{display: 'flex', float: 'left'}}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.startDate}
                    selectsStart
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}ˆ
                    onChange={ e => this.handleChangeStart(e)}
                    style={{float: 'left', position: 'relative'}}
                  />
                  <Calendar/>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.endDate}
                    selectsEnd
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    minDate={this.state.startDate}
                    onChange={ e => this.handleChangeEnd(e)}
                    style={{float: 'left', position: 'relative'}}
                  />
                  <CSVLink filename="data.csv" data={this.state.dataToDownload} headers={headersData}>
                    <IconButton size='small' style={{ marginLeft: '10px' }}>
                      <Archive style={{ color: 'white', width: 24, height: 24 }}/>
                    </IconButton>
                  </CSVLink>
              </div>
            </div>
          </div>
          <ReactTable
            className='-highlight'
            ref={this.reactTable}
            getTdProps={this._getTdProps}
            getTrProps={this._getTrProps}
            onFilteredChange={this.onTableViewChange}
            data={this.state.rows}
            columns={defaultColumnWidths(this.onClickAlert, this.deleteAlert)}
            filterable
            style={{height: '90%',width: '95%', backgroundColor: 'rgba(255,255,255, 0.9)', overflow: 'scroll', borderRadius: '4px'}}
            PadRowComponent={<span></span>} // the content rendered inside of a padding row
          />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginLeft: '70px',
          }}
          open={this.state.isShowSnack}
          autoHideDuration={5000}
          onClose={this.handleCloseSnack}
        >
          <SnackbarContent
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleCloseSnack}
              >
                <CloseIcon />
              </IconButton>,
            ]}
            message={
              <span id="client-snackbar" style={{
                alignItems: 'center',
                fontSize: 14,
                wordBreak: 'break-word'
              }}>
                {`Pour accéder aux données historiques plus de 45 jours dans le passé, merci de souscrire à l’offre D4 Historical. Contact : info@data4risk.com`}
              </span>
            }
          />
        </Snackbar>
        <AlertVisualisationDetail
          alertDetail={this.state.selectedRowInfo}
          open={this.state.openVisualisation}
          onClose={() => this.setState((prevState) => ({ openVisualisation: !prevState.openVisualisation }))}
        />
      </div>
    </div>
    );
  }
}

const defaultColumnWidths = (onClickAlert, deleteAlert) => [
  {
    Header: "Delete",
    id:'delete',
    width : 60,
    filterable: false,
    Cell: (row)=> (
      <IconButton onClick={()=>deleteAlert(row)} size='small' style={{textAlign: 'center', boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12'}}>
        <Delete/>
      </IconButton>
    )},
    {
      Header: "Modify",
      id:'modify',
      width : 60,
      filterable: false,
      Cell: (row)=> (
        <IconButton onClick={()=>onClickAlert(row)} size='small' style={{textAlign: 'center', boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12'}}>
          <Settings/>
        </IconButton>
      )},
  { accessor: 'type', Header: 'Type',
  filterMethod: (filter, row) => {
    if (filter.value === "all") {
      return true;
    }
    if (filter.value === filter.value) {
      return row[filter.id] === filter.value;
    }
  },
  Filter: ({ filter, onChange }) =>
  <select
    onChange={event => onChange(event.target.value)}
    style={{ width: "100%" }}
    value={filter ? filter.value : "all"}
  >
    <option value="all">SHOW ALL</option>
    <option value="HEAT_WAVE">HEAT WAVE</option>
    <option value="THUNDER">THUNDER</option>
    <option value="COLD_WAVE">COLD WAVE</option>
    <option value="WINDS">WINDS</option>
    <option value="HEAVY_RAIN">HEAVY RAIN</option>
    <option value="ICE">ICE</option>
    <option value="SNOW">SNOW</option>
    <option value="SUBMERSION">SUBMERSION</option>
    <option value="FLOOD">FLOOD</option>
    <option value="POLLUTION">POLLUTION</option>
  </select>},
   { accessor: 'country', Header: 'Country',
   filterMethod: (filter, row) => {
     if (filter.value === "all") {
       return true;
     }
     if (filter.value === filter.value) {
       return row[filter.id] === filter.value;
     }
   },
   Filter: ({ filter, onChange }) =>
   <select
     onChange={event => onChange(event.target.value)}
     style={{ width: "100%" }}
     value={filter ? filter.value : "all"}
   >
     <option value="all">SHOW ALL</option>
     <option value="FRANCE">FRANCE</option>
     <option value="ITALY">ITALY</option>
   </select>},
  { accessor: 'level', Header: 'Level',
  filterMethod: (filter, row) => {
    if (filter.value === "all") {
      return true;
    }
    if (filter.value === filter.value) {
      return row[filter.id] === filter.value;
    }},
  Filter: ({ filter, onChange }) =>
  <select
    onChange={event => onChange(event.target.value)}
    style={{ width: "100%" }}
    value={filter ? filter.value : "all"}
  >
    <option value="all">ALL</option>
    <option value="JAUNE">JAUNE</option>
    <option value="ORANGE">ORANGE</option>
    <option value="ROUGE">ROUGE</option>
  </select>},
  { accessor: 'previous_level', Header: 'Previous level',
  filterMethod: (filter, row) => {
    if (filter.value === "all") {
      return true;
    }
    if (filter.value === filter.value) {
      return row[filter.id] === filter.value;
    }},
  Filter: ({ filter, onChange }) =>
  <select
    onChange={event => onChange(event.target.value)}
    style={{ width: "100%" }}
    value={filter ? filter.value : "all"}
  >
    <option value="all">ALL</option>
    <option value="JAUNE">JAUNE</option>
    <option value="ORANGE">ORANGE</option>
    <option value="ROUGE">ROUGE</option>
    <option value="INCONNU">INCONNU</option>
  </select>},
  { id : 'expected_at' ,accessor: d => moment(d.expected_at).format('YYYY-MM-DD HH:mm').toString() , Header: 'Expected at', minWidth: 130 },
  { id : 'estimated_end', accessor: d => moment(d.estimated_end).format('YYYY-MM-DD HH:mm').toString(), Header: 'Estimated end', minWidth: 130 },
  { id : 'risk_duration', accessor: d => d.risk_duration + ' H', Header: 'Risk duration'},
  { accessor: 'postal_code', Header : 'Postal code'},
  { id : 'temp_min_h', accessor: d => d.temperatures_min_heatwave ? parseFloat(d.temperatures_min_heatwave).toFixed(2) : null, Header: 'Min Heatwave'},
  { id : 'temp_min_h', accessor: d => d.temperatures_max_heatwave ? parseFloat(d.temperatures_max_heatwave).toFixed(2) : null, Header: 'Max Heatwave'},
  { id : 'thunder_alt', accessor: d => d.thunder_winds_alltitude ? parseFloat(d.thunder_winds_alltitude).toFixed(2) : null, Header: 'Thunder alltitude'},
  { id : 'thunder_winds', accessor: d => d.thunder_winds_ground ? parseFloat(d.thunder_winds_ground).toFixed(2) : null, Header: 'Thunder ground'},
  { id : 'high_wind_avg', accessor: d => d.high_wind_average ? parseFloat(d.high_wind_average).toFixed(2) : null, Header: 'Wind '},
  { id : 'high_wind_g', accessor: d => d.high_wind_gust ? parseFloat(d.high_wind_gust).toFixed(2) : null, Header: 'Wind gust'},
  { id : 'frost_temp', accessor: d => d.frost_temperature ? parseFloat(d.frost_temperature).toFixed(2) : null, Header: 'Frost temperature'},
  { id : 'snow_low', accessor: d => d.snow_precipitation_low ? parseFloat(d.snow_precipitation_low).toFixed(2) : null, Header: 'Snow low'},
  { id : 'snow_high', accessor: d => d.snow_precipitation_high ? parseFloat(d.snow_precipitation_high).toFixed(2) : null, Header: 'Snow high'},
  { id: 'rain', accessor: d => d.rain ? parseFloat(d.rain).toFixed(2) : null  , Header : 'Rain'},
  {id : 'is_new', accessor: d => d.is_new.toString(), Header: 'Is new'},
  {id : 'level_changed', accessor: d => d.level_changed ? d.level_changed.toString() : null, Header: 'Level changed'},
]