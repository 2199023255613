import React , {Component} from "react";
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import {convert, Calendar, ButtonBlue} from './Convert';
import CalendarToday from '@material-ui/icons/CalendarToday';
import FlashMessage from  './FlashMessage';
import './Accordion.css'

const environment = process.env.NODE_ENV || 'development'
const config = require(`../../config/${environment}`)


const TextFieldRed = withStyles({ // Custom Textfield Red
  root: {
    '& label.Mui-focused': {
      color: '#FF4136',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '##FF4136',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#FF4136',
      },
    },
  },
})(TextField);

const TextFieldOrange = withStyles({  // Custom Textfield orange
  root: {
    '& label.Mui-focused': {
      color: '#FF851B',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FF851B',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#FF851B',
      },
    },
  },
})(TextField);

const TextFieldYellow = withStyles({  // Custom Textfield yellow
  root: {
    '& label.Mui-focused': {
      color: '#FFDC00',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFDC00',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#FFDC00',
      },
    },
  },
})(TextField);

const Chevron = (props) => {
  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill={props.fill}
        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
      />
    </svg>
  );
}

const initialState = { // state initial
  count : 0,
  setActive : "",
  setHeight : "0px",
  setRotate : "accordion__icon",
  startDate : new Date(),
  realEndDate : convert(new Date),
  endDate : new Date(),
  realStartDate: convert(new Date),
  canicule : {
    min : '',
    max : ''
  },
  extremeCold : {
    min : '',
    max : ''
  },
  wind : {
    level1 : '',
    level2 : '',
    level3 : '',
    altitude : ''
  },
  snow : {
    level1 : '',
    level2 : '',
    level3 : '',
    altitude : ''
  },
  ice : {
    altitude : ''
  }
}

class  Accordion extends Component{
  constructor(props)
  {
    super(props)
    this.state = initialState;
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.changeTreshold = this.changeTreshold.bind(this);
    this.myRef = React.createRef();
  }

  toggleAccordion() {
    if(this.state.count == 0)
    {
      this.setState({
        setHeight : this.state.setActive === "active" ? "0px" : `${this.myRef.current.scrollHeight}px`,
      });
      this.setState({
        setRotate : this.state.setActive === "active" ? "accordion__icon" : "accordion__icon rotate",
        setActive : this.state.setActive === "" ? "active" : "",
        count : this.state.count + 1
      })
    }
    else{
      this.setState(initialState)
    }
  }

  changeTreshold(){ // send all value to api/change/threshold

    const body = {
      value : this.state[this.props.state],
      name : this.props.state,
      current : this.props.current,
      startDate : this.state.realStartDate,
      endDate : this.state.realEndDate,
      token : localStorage.getItem('TOKEN'),
    }

    //http://localhost:3400/api/change/threshold
    //https://threshold.data4risk.com/api/change/threshold
    axios.post(`/api/change/threshold`, body, (e, data) => {}, Number.isInteger(parseInt(body.current)) ? setTimeout( () =>this.props.getCurrentTreshold(), 300) : null)
    this.setState(initialState);
  }

  handleChangeStart(e){ // on change startdate
    this.setState({
        startDate : e,
        realStartDate : convert(e) // the format date for allianz api
    })
}

  handleChangeEnd(e){ // on change endDate
    this.setState({
        endDate : e,
        realEndDate : convert(e) // the format date for allianz api
    })
  }

  componentDidUpdate(prevProps){ // if click on region or department or search postcode, will restart the state
    if(this.props.current != prevProps.current)
    {
      this.setState(initialState)
    }
  }
  render(){
    console.log(this.state)
  return (
    <div>
        <div className="accordion__section">
          <button className={`accordion ${this.state.setActive}`} onClick={this.toggleAccordion}>
          <img className="accordion_image" src={this.props.logo}  width={'50px'} height={'50px'}/>
            <p className="accordion__title" style={{marginLeft: '30px'}}>{this.props.title}</p>
            <Chevron className={`${this.state.setRotate}`} width={10} fill={"#777"} />
          </button>
          {
            this.state.setActive == 'active' ?
            <Divider/> : null
          }
          <div
            ref={this.myRef}
            style={{ maxHeight: `${this.state.setHeight}`,}}
            className="accordion__content"
          >
          {this.props.title === 'Heatwave' || this.props.title === 'Coldwave'?
          <div style={{flex : 1}}>
            <FlashMessage current={this.props.current} myRef={this.myRef.current}/>
            <div style={{display : 'flex', justifyContent:'space-between', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px'}}>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.startDate}
                  selectsStart
                  withPortal
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={ e => this.handleChangeStart(e)}
                  style={{float: 'left', position: 'relative', marginleft:'10px'}}
                />
                <Calendar/>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.endDate}
                  selectsEnd
                  withPortal
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.startDate}
                  onChange={ e => this.handleChangeEnd(e)}
                  style={{float: 'right', position: 'relative', marginRight:'10px'}}
                />
            </div>
            <TextField
            id={"outlined-dense-min" + Math.random()}
            label="Min threshold"
            margin="dense"
            variant="outlined"
            style={{flex : 1, float: 'left', marginLeft: '10px', width: '42%' }}
            value={this.state[this.props.state].min}
            onChange={e => this.setState({[this.props.state] : {...this.state[this.props.state], ['min'] : e.target.value}})}
          />
          <TextFieldRed
            id={"outlined-dense-max" + Math.random()}
            label="Max threshold"
            margin="dense"
            variant="outlined"
            style={{flex : 1, float: 'right', marginRight: '10px', width: '42%'}}
            value={this.state[this.props.state].max}
            onChange={e => this.setState({[this.props.state] : {...this.state[this.props.state], ['max'] : e.target.value}})}
          />
        <ButtonBlue variant="contained" onClick={this.changeTreshold} style={{alignSelf: 'center', margin : '10px', flex : 1, width : '-webkit-fill-available'}}>
          Save
        </ButtonBlue>
        </div>
        :
          <div style={{flex : 1}}>
          <FlashMessage current={this.props.current}/>
          <div style={{display : 'flex', justifyContent:'space-between', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px'}}>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.startDate}
                  selectsStart
                  withPortal
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={ e => this.handleChangeStart(e)}
                  style={{float: 'left', position: 'relative', marginleft:'10px'}}
                />
                <Calendar/>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.endDate}
                  selectsEnd
                  withPortal
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.startDate}
                  onChange={ e => this.handleChangeEnd(e)}
                  style={{float: 'right', position: 'relative', marginRight:'10px'}}
                />
            </div>
            {this.props.title === 'Wind' || this.props.title === 'Snow' ?
              <div>
                      <TextFieldYellow
                      id={"outlined-dense-yellow" + Math.random()}
                      label="Yellow level"
                      margin="dense"
                      variant="outlined"
                      style={{flex : 1, float: 'left', marginLeft: '10px', width: '45%'}}
                      value={this.state[this.props.state].level1}
                      onChange={e => this.setState({[this.props.state] : {...this.state[this.props.state], ['level1'] : e.target.value}})}
                    />
                    <TextFieldOrange
                      id={"outlined-dense-orange" + Math.random()}
                      label="Orange level"
                      margin="dense"
                      variant="outlined"
                      value={this.state[this.props.state].level2}
                      style={{flex : 1,  float: 'right', marginRight:'10px', width: '45%'}}
                      onChange={e => this.setState({[this.props.state] : {...this.state[this.props.state], ['level2'] : e.target.value}})}
                    />
                    <TextFieldRed
                      id={"outlined-dense-red" + Math.random()}
                      label="Red level"
                      margin="dense"
                      variant="outlined"
                      style={{float: 'left', marginLeft: '10px', width: '45%'}}
                      value={this.state[this.props.state].level3}
                      onChange={e => this.setState({[this.props.state] : {...this.state[this.props.state], ['level3'] : e.target.value}})}
                    />
                     <TextField
                      id={"outlined-dense-alt" + Math.random()}
                      label="Altitude"
                      margin="dense"
                      variant="outlined"
                      style={{flex : 1,  float: 'right', marginRight:'10px', width: '45%'}}
                      value={this.state[this.props.state].altitude}
                      onChange={e => this.setState({[this.props.state] : {...this.state[this.props.state], ['altitude'] : e.target.value}})}
                    /> </div>
                    :
                    <TextField
                    id={"outlined-dense-alt" + Math.random()}
                    label="Altitude"
                    margin="dense"
                    variant="outlined"
                    style={{alignSelf: 'center', margin : '10px', flex : 1, width : '-webkit-fill-available'}}
                    value={this.state[this.props.state].altitude}
                    onChange={e => this.setState({[this.props.state] : {...this.state[this.props.state], ['altitude'] : e.target.value}})}
                  />
            }
          <ButtonBlue variant="contained" onClick={this.changeTreshold} style={{alignSelf: 'center', margin : '10px', flex : 1, width : '-webkit-fill-available'}}>
            Save
          </ButtonBlue>
          </div>}
          </div>
      </div>
    </div>
  )};
}

export default Accordion;