
//MAPBOX LAYER TO COLOR ALERT ON MAP 

const layout = {
  'line-join': 'round',
  'line-cap': 'round',
};

export const windLayer = {
    id: 'wind',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "WINDS"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#005c00',
        'green'
      ]
    },
  };
  
  export const thunderLayer = {
    id: 'thunder',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "THUNDER"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#b8b800',
        'yellow'
      ]
    },
  };

  export const greleLayer = {
    id: 'grele',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "hail"], true ]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        'pink',
        'pink'
      ]
    },
  };


  export const thunderLayerv2 = {
    id: 'thunderv2',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "THUNDER_V2"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#d1ae23',
        '#ffd428',
      ]
    },
  };

  export const thunderLayerv3 = {
    id: 'thunderv3',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "THUNDER_V3"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#e0471d',
        '#9e2709',
      ]
    },
  };

  export const heatWaveLayer = {
    id: 'heatWave',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "HEAT_WAVE"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#a80000',
        'red'
      ]
    },
  };

  export const coldWaveLayer = {
    id: 'coldWave',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "COLD_WAVE"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#0000c7',
        'blue'
      ]
    },
  };

  export const iceLayer = {
    id: 'ice',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "ICE"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#4d4d4d',
        'grey'
      ]
    },
  };

  export const snowLayer = {
    id: 'snow',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "SNOW" || "SNOWV2"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#c9c9c9',
        'white'
      ]
    },
  };

  export const heavyRainLayer = {
    id: 'rain',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "HEAVY_RAIN"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#00b8b8',
        'aqua'
      ]
    },
  };


  export const submersionLayer = {
    id: 'submersion',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "SUBMERSION"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#0062ad',
        '#0588bc'
      ]
    },
  };

  export const crueLayer = {
    id: 'crue',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "FLOOD"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#11BA96',
        '#16FFCE'
      ]
    },
  };

  export const pollutionLayer = {
    id: 'pollution',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "POLLUTION"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#a41cff',
        '#7113af'
      ]
    },
  };