import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles'
import { Group } from '@vx/group';
import { LinePath } from '@vx/shape';
import { scaleTime, scaleLinear, scaleOrdinal } from '@vx/scale';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { GridRows, GridColumns } from '@vx/grid';
import { LegendOrdinal } from '@vx/legend';
import { curveMonotoneX } from '@vx/curve';
import { timeFormat } from 'd3-time-format';

const bg = '#fff';

const margin = {
  left: 60,
  bottom: 40,
  right: 40,
  top: 80,
};

const useStyles = makeStyles({
  root: {
    position: 'relative'
  },
  legend: {},
  vxLegend: {
    justifyContent: 'center'
  }
})

const format = timeFormat('%d/%m %Hh');
const formatDate = date => format(date);

// accessors
const x = d => moment.utc(d.date).toDate();

const AlertLine = (props) => {
  const { alertType, data, keys, colors, width, height, verticalAxisUnit } = props;
  const classes = useStyles(props);
  const { t } = useTranslation('aggregatedAlert')

  // bounds
  const xMax = width -margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const yScaleDomain = _.reduce(data, (domain, d) => {
    const values = _.values(_.pick(d, keys))
    const maxValue = _.max(values)
    const minValue = _.min(values)
    if (domain[0] > minValue && minValue < 0) domain[0] = Math.floor(minValue)
    if (domain[1] < maxValue) domain[1] = Math.ceil(maxValue)
    return domain
  }, [0, 0]);

  // scales
  const xScale = scaleTime({
    domain: [Math.min(..._.map(data, x)), Math.max(..._.map(data, x))],
    range: [0, xMax]
  });

  const yScale = scaleLinear({
    domain: yScaleDomain,
    range: [yMax, 0]
  });

  const color = scaleOrdinal({
    domain: keys,
    range: colors,
  });

  return (
    <div className={classes.root} style={{ width }}>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={bg} rx={14} />
        <Group top={margin.top} left={margin.left}>
          <GridRows
            lineStyle={{ pointerEvents: 'none' }}
            scale={yScale}
            width={xMax}
            strokeDasharray='3,3'
            stroke='#ccc'
          />
          <GridColumns
            lineStyle={{ pointerEvents: 'none' }}
            scale={xScale}
            height={yMax}
            strokeDasharray='3,3'
            stroke='#ccc'
          />
          {_.map(keys, (key, index) => (
            <Group key={key}>
              <LinePath
                curve={curveMonotoneX}
                data={data}
                x={d => xScale(x(d))}
                y={d => yScale(d[key])}
                stroke={colors[index]}
                strokeWidth={1}
              />
              {/* Code below for displaying points */}
              {/* {_.map(data, (d) => (
                <circle
                  key={`${moment(d.date).unix()}_${d[key]}`}
                  cx={xScale(x(d))}
                  cy={yScale(d[key])}
                  r={4}
                  fill='rgba(92, 119, 235, 1.000)'
                  stroke='white'
                  strokeWidth={2}
                  style={{ pointerEvents: 'none' }}
                />
              ))} */}
            </Group>
          ))}
          <AxisLeft
            numTicks={4}
            scale={yScale}
            tickFormat={v => `${v}${verticalAxisUnit || ''}`}
          />
          <AxisBottom
            // numTicks={4}
            scale={xScale}
            tickFormat={formatDate}
            tickValues={_.map(_.range(4), v => _.map(data, x)[v*(Math.floor(data.length/4))])}
            top={yMax}
          />
        </Group>
      </svg>
      <div className={classes.legend}>
        <LegendOrdinal
          className={classes.vxLegend}
          scale={color}
          direction='row'
          itemDirection='row'
          labelFormat={d => t(`${_.toLower(alertType)}.${d}`)}
          labelMargin="0 15px 0 0"
        />
      </div>
    </div>
  );
};

AlertLine.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  verticalAxisUnit: PropTypes.string
};

AlertLine.defaultProps = {
  width: 800,
  height: 480
};

export default AlertLine;
