import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="70%"
      height="70%"
      viewBox="0 0 105 76"
      className='icon-svg'
    >
      <defs>
        <path
          id="a"
          d="M0.0322340426 0.304546763L100.746157 0.304546763 100.746157 75.9989065 0.0322340426 75.9989065z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-69 -357) translate(69 357) translate(4)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill="#FFF"
            d="M50.372.305c-20.505 0-37.187 16.979-37.187 37.848 0 20.868 16.682 37.846 37.187 37.846S87.561 59.02 87.561 38.153C87.56 17.283 70.877.305 50.372.305zm0 69.135c-16.95 0-30.74-14.037-30.74-31.287s13.79-31.287 30.74-31.287c16.952 0 30.742 14.036 30.742 31.287 0 17.25-13.79 31.287-30.742 31.287z"
            mask="url(#b)"
          />
          <path
            fill="#FFF"
            d="M19.296 6.524a3.325 3.325 0 000-4.64 3.186 3.186 0 00-4.56 0c-19.649 19.999-19.649 52.54 0 72.536a3.186 3.186 0 004.56 0 3.32 3.32 0 000-4.638C2.16 52.34 2.16 23.965 19.296 6.524zM86.008 1.885a3.184 3.184 0 00-4.558 0 3.322 3.322 0 000 4.639c17.137 17.44 17.137 45.817 0 63.258a3.321 3.321 0 000 4.638 3.185 3.185 0 004.558 0c19.65-20 19.65-52.537 0-72.535z"
            mask="url(#b)"
          />
        </g>
        <path
          fill="#ED080A"
          d="M50.15 48.3c1.744 0 3.156-1.483 3.156-3.31V16.804c0-1.826-1.412-3.309-3.157-3.309-1.742 0-3.156 1.483-3.156 3.31V44.99c0 1.827 1.414 3.31 3.156 3.31z"
          transform="translate(-69 -357) translate(69 357) translate(4)"
        />
        <g transform="translate(-69 -357) translate(69 357) translate(0 33)">
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M3.67394449 35.1851852L2.08695652 32.609465 29.0217077 14.7777778 30.6086957 17.3534979z"
          />
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M52.0403628 27.4444444L50.0869565 25.1833971 77.3509415 1.40740741 79.3043478 3.66845471z"
          />
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M32.3117053 19.4771397L33.2384645 16.8230291 55.7209034 24.8561936 54.7941442 27.5103043z"
          />
          <ellipse cx="29.217" cy="17.593" fill="#FFF" rx="4.174" ry="4.222" />
          <ellipse cx="53.87" cy="24.63" fill="#ED080A" rx="4.174" ry="4.222" />
          <ellipse cx="75.826" cy="4.222" fill="#FFF" rx="4.174" ry="4.222" />
          <ellipse cx="4.174" cy="33.778" fill="#FFF" rx="4.174" ry="4.222" />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
