import React from 'react';
import { InputPicker, Input} from 'rsuite';
import DatePicker ,{registerLocale }from 'react-datepicker';
const environment = process.env.NODE_ENV || 'development'
const config = require(`../../config/${environment}`)
import fr from "date-fns/locale/fr";
import {parseISO} from "date-fns";
import {ButtonBlue ,objectValue ,dataBool, dataPreviousLevel, dataLevel, roundMinutes, dataCountry, ButtonRed} from './Convert';
import {Typography} from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';
import {diff, applyChange} from 'deep-diff';
import _ from 'lodash'


/// component to display input with property of alert in alert editor and in modify alert

// if this.props we modified else we add an alert.

registerLocale('fr', fr)
export default class MapInput extends React.Component{
    constructor(props) {
      super(props)
      this.state = {
        alert : props.alert ?Object.assign({}, props.alert) : objectValue[props.type],
        startDate : props.alert ?  new Date (moment(parseISO(props.alert.expected_at))) : new Date(moment().minutes(0).seconds(0).milliseconds(0)),
        minDate :  props.alert ?   new Date (moment(parseISO(props.alert.expected_at))) : new Date(moment(new Date()).add({hours: 7}).minutes(0).seconds(0).milliseconds(0)),
        endDate : props.alert ?   new Date (moment(parseISO(props.alert.estimated_end))) :  new Date(moment(new Date()).add({hours: 7}).minutes(0).seconds(0).milliseconds(0)),
        minTime : props.alert ?   new Date (moment(parseISO(props.alert.expected_at))) : roundMinutes(moment(new Date()).add({hours: 0}).toDate()),
        maxTime : props.alert ? moment().endOf('day').toDate() : moment().endOf('day').toDate(),
        error : '',
        success : ''
      };
      this.saveAlertChanges = this.saveAlertChanges.bind(this)
      this.handleError = this.handleError.bind(this)
      this.handleChange = this.handleChange.bind(this)
    };

    componentDidMount()
    {
        if(document.getElementById("risk_duration").value !== 0)
        {
            const value = document.getElementById("risk_duration").value
            const state = Object.assign({}, this.state)
            state.alert.risk_duration = parseInt(value);
            this.setState(state)
        }
        if(this.props.type)
        {
          this.setState({alert : {...this.state.alert , type : this.props.type}})
        }
    }

    componentDidUpdate(prevProps, prevState) //update risk duration if startdate or endate changed
    {
        if(this.props.type && this.props.type != prevProps.type)
        {
          const value = document.getElementById("risk_duration").value
            const state = Object.assign({}, this.state)
            const alert = Object.assign({}, objectValue[this.props.type])
            state.alert = alert
            state.alert.risk_duration = parseInt(value);
            state.alert.type = this.props.type
            this.setState(state)
        }
        if(this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate)
        {
          const value = document.getElementById("risk_duration").value
          const state = Object.assign({}, this.state)
          state.alert.risk_duration = parseInt(value);
          this.setState(state)
        }
        if(this.state.success && this.state.success != prevState.success)
        {
          const value = document.getElementById("risk_duration").value
          const state = Object.assign({}, this.state)
          const alert = Object.assign({}, objectValue[this.props.type])
          state.alert = alert
          state.alert.risk_duration = parseInt(value);
          state.alert.type = this.props.type
          this.setState(state)
        }
    }

    handleChange(text, type, keys) // complexe onChange to handle date and other input
    {
        if(type === 'expected_at')
        {
            const state = Object.assign({}, this.state)
            state.alert[type] = moment(text).minutes(0).seconds(0).milliseconds(0).format();
            state.startDate = text;
            state.error = ""
            this.setState(state);
        }
        else if(type === 'estimated_end')
        {
            const state = Object.assign({}, this.state)
            state.alert[type] = moment(text).minutes(0).seconds(0).milliseconds(0).format();
            state.endDate = text
            state.error = ""
            this.setState(state);
        }
        else
        {
          const state = Object.assign({}, this.state)
          state.alert[type] = text;
          state.error = ""
          this.setState(state);
        }
    }


    handleError() // handle error in form
    {
        const {alert} = this.state;
        const keys = this.props.alert ? Object.keys(this.props.alert) : Object.keys(objectValue[this.props.type])
        const simpleKeys = this.props.alert ? Object.keys(objectValue['SIMPLEPROP']) : Object.keys(objectValue['SIMPLE'])
        const arrayDiff = keys ? keys.filter(x => !simpleKeys.includes(x)) : null
        let error = ''

        if(!this.props.alert && keys.length != Object.keys(this.state.alert).length - 1)
            error = 'You need to fill all the fields !'
        else if(!Number.isInteger(alert.risk_duration))
            error = "Risk duration must to be a number !"
        else if((alert.postal_code.length != 5) || Number.isInteger(parseInt(alert.postal_code) != true))
            error = 'Wrong postal code !'
        else if (this.props.alert && diff(alert, this.props.alert) === undefined)
            error = 'Nothing change !'
        else if (this.props.alert && diff(alert, this.props.alert) === undefined)
            error = 'Nothing change !'
        else if (arrayDiff)
        {
          arrayDiff.map(x => {
            !parseInt(this.state.alert[x]) ? error = `${x} need to be a number !` : null
          })
        }

        return (error ? error : 1)
    }

    saveAlertChanges(keys) //method to send the new alert changes or the new alert
    {
            const {alert} = this.state
            const res = this.handleError(keys)
            if(res !== 1)
            {
              this.setState({error : res, success : ''})
              return;
            }
            if(this.props.alert)
            {
              const diffObject = {}
              diff(this.props.alert, this.state.alert).reverse().forEach(function(diffItem) {
                applyChange(diffObject, true, diffItem);
              });
              const body = {
                  id : this.props.alert.id,
              }
              Object.assign(body, diffObject)
              Axios.post('/alertapi/manage?operation=UPDATE', body)
              .then(data => {
                  this.setState({success : `New Alert Update !`, error : ''})
              })
              .catch(err => {
                  this.setState({error : 'Bad request !', success : ''})
              })
              }
            else
            {
            Axios.post('/alertapi/manage?operation=ADD', alert)
            .then(data => {
                this.setState({success : `New Alert ${"id : " + data.data.data}`, error : ''})
            })
            .catch(err => {
                console.log(err)
                this.setState({error : 'Bad request !', success : ''})
            })
            }
      /*
      this.props.history.push({
        pathname: '/table',
      })
      */
    }
    render(){
      const keys = this.props.alert ? Object.keys(_.omit(this.props.alert, ['inserted_at', 'country'])).reverse() : Object.keys(objectValue[this.props.type]).sort( function(){ return })
      return(
        <div id='test' style={{width : '100%'}}>
        {keys.map((data, index)=>(
          data === 'level' ?
          <div key={index} style={{margin : 5}}>
          <label>{data}</label>
          <InputPicker defaultValue={this.props.alert ? this.props.alert[data] : 'ROUGE'} data={dataLevel} onChange={(e => this.handleChange(e, data, keys))} size='large' style={{ width: '100%'}}/>
        </div> :
        data === 'country' ?
        <div key={index} style={{margin : 5}}>
        <label>{data}</label>
        <InputPicker defaultValue={'FRANCE'} data={dataCountry} onChange={(e => this.handleChange(e, data, keys))} size='large' style={{ width: '100%'}}/>
      </div> :
        data === 'previous_level' ?
        <div key={index} style={{margin : 5}}>
        <label>{data}</label>
        <InputPicker defaultValue={this.props.alert ? this.props.alert[data] : 'INCONNU'} data={dataPreviousLevel} onChange={(e => this.handleChange(e, data, keys))} size='large' style={{ width: '100%'}}/>
        </div> :
        data === 'is_new' || data === 'level_changed'?
        <div key={index} id='parse-int' data={data} style={{margin : 5}}>
        <label>{data}</label>
        <InputPicker defaultValue={this.props.alert ? this.props.alert[data].toString() : 'true'} data={dataBool} onChange={(e => this.handleChange(e, data, keys))} size='large' style={{ width: '100%'}}/>
      </div> :
      data === 'expected_at' ?
      <div key={index} style={{margin : 5}}>
        <label>{data}</label>
        <DatePicker
        locale={'fr'}
        selected={this.state.startDate}
        onChange={date => this.handleChange(date, 'expected_at', keys)}
        withPortal
        selectsStart
        showTimeSelect
        width={'100%'}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        minDate={this.state.minDate}
        minTime={this.state.minTime}
        maxTime={this.state.maxTime}
        timeIntervals={60}
        dateFormat="yyyy, MMMM, dd,  HH:mm"
        />
      </div> :
       data === 'estimated_end' ?
       <div key={index} style={{margin : 5}}>
         <label>{data}</label>
         <DatePicker
         locale={'fr'}
         selected={this.state.endDate}
         onChange={date => this.handleChange(date, 'estimated_end',keys)}
         withPortal
         selectsEnd
         showTimeSelect
         timeIntervals={60}
         startDate={this.state.startDate}
         endDate={this.state.endDate}
         minDate={this.state.minDate}
         minTime={this.state.minTime}
         maxTime={this.state.maxTime}
         dateFormat="yyyy, MMMM, dd,  HH:mm"
         />
       </div> :
       data === 'risk_duration' ?
       <div key={index} style={{margin : 5}}>
          <label>{data}</label>
          <Input disabled={true} id={'risk_duration'} value={Math.abs(this.state.endDate - this.state.startDate) / 3600000}/>
        </div> :
        this.props.alert ?
        <div key={index} style={{margin : 5}}>
          <label>{data}</label>
          <Input defaultValue={this.state.alert[data]} disabled={data === 'id' ? true : false || data === 'type' ? true : false} onChange={(e => this.handleChange(e, data, keys))}/>
        </div> :
        data === 'type' ? null :
        <div key={index} style={{margin : 5}}>
          <label>{data}</label>
          <Input type='text' value={this.state.alert[data] || ""} onChange={ (e) =>  this.handleChange(e, data, keys)}/>
        </div>
      ))}
        <div style={{ alignSelf: 'center', textAlign: 'center'}}>
        {
            this.state.success ? <Typography color='primary' >{this.state.success}</Typography> : <Typography color='error' >{this.state.error}</Typography>
        }
        <ButtonBlue style={{width : '80%',  margin: 10}} onClick={() => this.saveAlertChanges(keys)}>SAVE</ButtonBlue>
        <ButtonRed style={{width : '80%',  margin: 10}} onClick={ () =>  this.props.history.push('/table')}>Cancel</ButtonRed>
        </div>
      </div>)
    }
  }