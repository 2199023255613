import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import Sidebar from '../components/SideBar';
import Loading from './Loading';;

//COMPONENT TO KNOW IF USER IS ADMIN to protect admin page

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
        admin : null,
        role: null
      };
    }

    async componentDidMount() {
      const token = await localStorage.getItem('TOKEN');
      if (token)
      {
        Axios.post(`/api/getAdmin`, {token : token})
        .then(res => {
          if (res.status === 200) {
            setTimeout(() => {
              this.setState({ loading: false , admin : res.data.admin, role: res.data.role });
            }, 200)
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch(async (err) => {
          console.error(err);
          // fix bug : remove local storage to avoir redirect loop
          // TODO: refactor auth flow
          await localStorage.removeItem('TOKEN');
          this.setState({ loading: false, redirect: true });
        });
      }
      else
        this.setState({ loading: false, redirect: true });
    }

    render() {
      const { loading, redirect } = this.state;
      if(this.state.admin === false && this.props.location.pathname === '/admin')
        return <Redirect to="/alerts" />
      if (loading) {
        return(
          <Loading admin={this.state.admin}/>
        )
      }
      if (((!!this.state.role && this.state.role.toLowerCase() !== 'streetview') || !this.state.role)
          && this.state.admin === false && this.props.location.pathname === '/streetview') {
        return <Redirect to="/alerts" />
      }
      if (!!this.state.role && this.state.role.toLowerCase() === 'streetview'
          &&this.state.admin === false && this.props.location.pathname !== '/streetview') {
        return <Redirect to="/streetview" />
      }
      if (redirect) {
        return <Redirect to="/" />;
      }
      return (
        <React.Fragment>
          <Sidebar admin={this.state.admin} role={this.state.role}/>
          <ComponentToProtect admin={this.state.admin} role={this.state.role} {...this.props} key={this.props.location.pathname}/>
        </React.Fragment>
      );
    }
  }
}
