import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const BlueStart = (props) => {
  return (
    <SvgIcon
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='32'
      height='30'
      viewBox='0 0 32 30'
    >
      <title>star</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-845.000000, -1052.000000)" fill="#1B5E90" fillRule="nonzero">
          <g id="star" transform="translate(845.000000, 1052.000000)">
            <path d="M7.1622726,30 C6.81167803,30 6.4635249,29.8929987 6.16639846,29.6828177 C5.61291808,29.2904001 5.35436677,28.6159101 5.50500523,27.9650654 L7.56364967,19.0937535 L0.58203244,13.1040715 C0.0688361776,12.6657962 -0.127213525,11.971721 0.0834850384,11.33664 C0.294183539,10.702753 0.867439843,10.2539687 1.54616749,10.1925863 L10.7836997,9.37216376 L14.4358892,1.01101857 C14.705183,0.396716702 15.3184793,0 15.9998926,0 C16.6813058,0 17.2946022,0.396716702 17.5638959,1.00958548 L21.2160855,9.37216376 L30.4521527,10.1925863 C31.1323453,10.2525357 31.7056016,10.702753 31.9163002,11.33664 C32.1269987,11.9705269 31.9321697,12.6657962 31.4189734,13.1040715 L24.4373562,19.0925593 L26.4960006,27.9636323 C26.6468833,28.6159101 26.3880879,29.2904001 25.8348515,29.6816235 C25.282836,30.0728468 24.5467339,30.102941 23.9654208,29.7611579 L15.9998926,25.1044536 L8.03436439,29.7637852 C7.76507063,29.9202268 7.46501443,30 7.1622726,30 Z M15.9998926,23.0984189 C16.3026344,23.0984189 16.6024464,23.1779533 16.8719843,23.3343948 L24.3895034,27.7329108 L22.4465845,19.3598236 C22.3079093,18.7639125 22.5147014,18.1417289 22.9854161,17.7385634 L29.5776195,12.0827827 L20.8559692,11.3079789 C20.228024,11.251851 19.6879717,10.8656434 19.4426043,10.2995876 L15.9998926,2.40920015 L12.5530304,10.3007818 C12.3103486,10.8630161 11.7702962,11.2492237 11.143816,11.3053516 L2.42094491,12.0801554 L9.01290411,17.7359362 C9.48508368,18.1402958 9.69163176,18.7612853 9.55173576,19.3586293 L7.6102817,27.7314778 L15.1278008,23.3343948 C15.3970945,23.1779533 15.6971508,23.0984189 15.9998926,23.0984189 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default BlueStart