import { withStyles, Button } from '@material-ui/core';
import moment from 'moment';
import CalendarToday from '@material-ui/icons/CalendarToday';

/// UTILS.JS --- export const and function


export const Calendar = withStyles({ //custom calendar icon
    root: {
        color: '#1C5D90'
    }
})(CalendarToday);

export const CalendarWhite = withStyles({ // custom calendar icon white
    root: {
        color: 'white'
    }
})(CalendarToday);

export function returnColor (str) // return div colored to display different level of alert on popup
{
    if (!str) {return;}
    if (str === 'JAUNE') {return 'yellow';}
    if (str === 'ORANGE') {return 'orange';}
    if (str === 'ROUGE') {return 'red';}
    if (str === 'INCONNU') {return 'grey';}
}

export const roundMinutes = (date) => { // function to round Date time
    date.setHours(date.getHours() + (date.getMinutes() / 60));
    date.setMinutes(0);

    return date;
};

export function convert (str) { // function to convert date to send to the allianz api
    var date = new Date(str);
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

export const ButtonBlue = withStyles({ //custom buuton blue
    root: {
        '&:hover': {
            backgroundColor: '#164c75'
        },
        "backgroundColor": '#1C5D90',
        "color": '#fff'
    }
})(Button);

export const ButtonRed = withStyles({ //custom buuton blue
    root: {
        '&:hover': {
            backgroundColor: '#c9211e'
        },
        "backgroundColor": '#f44340',
        "color": '#fff'
    }
})(Button);

export const data = [ // data for type input select in alert editor 
  {
    label: 'Heat wave',
    value: 'HEAT_WAVE',
    role: 'Master'
  }, {
    label: 'Cold wave',
    value: 'COLD_WAVE',
    role: 'Master'
  }, {
    label: 'Winds',
    value: 'WINDS',
    role: 'Master'
  }, {
    label: 'Thunder',
    value: 'THUNDER',
    role: 'Master'
  }, {
    label: 'Ice',
    value: 'ICE',
    role: 'Master'
  },
  {
    label: 'Heavy Rain',
    value: 'HEAVY_RAIN',
    role: 'Master'
  },
  {
    label: 'Snow',
    value: 'SNOW',
    role: 'Master'
  },
  {
    label: 'Submersion',
    value: 'SUBMERSION',
    role: 'Master'
  },
  {
    label: 'Flood',
    value: 'FLOOD',
    role: 'Master'
  }]

export const dataLevel = [ // data for level input select in Alert editor
    {
        label: 'YELLOW',
        value: 'JAUNE',
        role: 'Master'
    },
    {
        label: 'ORANGE',
        value: 'ORANGE',
        role: 'Master'
    },
    {
        label: 'RED',
        value: 'ROUGE',
        role: 'Master'
    }
];

export const dataCountry = [ // data for country input select in alert editor
    {
        label: 'FRANCE',
        value: 'FRANCE',
        role: 'Master'
    },
    {
        label: 'ITALY',
        value: 'ITALY',
        role: 'Master'
    }
];

export const dataPreviousLevel = [ //  data for previous_level input select in alert editor
    {
        label: 'YELLOW',
        value: 'JAUNE',
        role: 'Master'
    },
    {
        label: 'ORANGE',
        value: 'ORANGE',
        role: 'Master'
    },
    {
        label: 'RED',
        value: 'ROUGE',
        role: 'Master'
    },
    {
        label: 'UNKNOWN',
        value: 'INCONNU',
        role: 'Master'
    }
];

export const dataBool = [ //input select boolean for is_new
    {
        label: 'true',
        value: 'true',
        role: 'Master'
    },
    {
        label: 'false',
        value: 'false',
        role: 'Master'
    }
];

const sevenHours = roundMinutes(moment(new Date()).add({ hours: 7 }).minutes(0).seconds(0).milliseconds(0).toDate());

export const objectValue = { // object value to get the property of each type alerts 
  WINDS : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "false",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    country : 'FRANCE',
    high_wind_average : "",
    high_wind_gust : "",
  },
  THUNDER : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "false",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    country : 'FRANCE',
    thunder_winds_altitude: "",
    thunder_winds_ground: "",
  },
  HEAT_WAVE : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
      estimated_end : moment(sevenHours).format(),
      level : "ROUGE",
      is_new : "true",
      level_changed : "false",
      postal_code : "",
      previous_level : "INCONNU",
      risk_duration : "",
      country : 'FRANCE',
      temperatures_min_heatwave : "",
      temperatures_max_heatwave : "",
  },
  COLD_WAVE : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
      estimated_end : moment(sevenHours).format(),
      level : "ROUGE",
      is_new : "true",
      level_changed : "false",
      postal_code : "",
      previous_level : "INCONNU",
      risk_duration : "",
      country : 'FRANCE',
      temperatures_min_coldfront : "",
      temperatures_max_coldfront : "",
  },
  SNOW : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
      estimated_end : moment(sevenHours).format(),
      level : "ROUGE",
      is_new : "true",
      level_changed : "false",
      postal_code : "",
      previous_level : "INCONNU",
      risk_duration : "",
      country : 'FRANCE',
      snow_precipitation_low: "",
      snow_precipitation_high: ""
  },
  ICE : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
      estimated_end : moment(sevenHours).format(),
      level : "ROUGE",
      is_new : "true",
      level_changed : "false",
      postal_code : "",
      previous_level : "INCONNU",
      country : 'FRANCE',
      risk_duration : "",
  },
  HEAVY_RAIN : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
      estimated_end : moment(sevenHours).format(),
      level : "ROUGE",
      is_new : "true",
      level_changed : "false",
      postal_code : "",
      previous_level : "INCONNU",
      risk_duration : "",
      rain : "",
      country : 'FRANCE'
  },
  SUBMERSION : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
      estimated_end : moment(sevenHours).format(),
      level : "ROUGE",
      is_new : "true",
      level_changed : "false",
      postal_code : "",
      previous_level : "INCONNU",
      risk_duration : "",
      rain : "",
      country : 'FRANCE',
     significant_height: ''
  },
  FLOOD : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
      estimated_end : moment(sevenHours).format(),
      level : "ROUGE",
      is_new : "true",
      level_changed : "false",
      postal_code : "",
      previous_level : "INCONNU",
      risk_duration : "",
      rain : "",
      country : 'FRANCE',
      section : ''
  },
  POLLUTION : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
      estimated_end : moment(sevenHours).format(),
      level : "ROUGE",
      is_new : "true",
      level_changed : "false",
      postal_code : "",
      previous_level : "INCONNU",
      risk_duration : "",
      rain : "",
      country : 'FRANCE',
      polluants : []
  },
  SIMPLE : { // simple alert are commons property to all alerts
    expected_at : null,
    estimated_end : null,
    is_new : null,
    level : null,
    is_new : null,
    level_changed : null,
    postal_code : null,
    previous_level : null,
    risk_duration : null,
    country : null
  },
  SIMPLEPROP : { 
    type : null,
    id : null,
    expected_at : null,
      estimated_end : null,
    is_new : null,
    level : null,
    is_new : null,
    level_changed : null,
    postal_code : null,
    previous_level : null,
    risk_duration : null,
  },
}
