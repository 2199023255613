import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translations from './assets/translations'

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    ns: ['app', 'aggregatedAlert'],
		defaultNS: 'app',
    resources: translations,
		debug: process.env.NODE_ENV === 'development'
  })

export default i18n
