import React from 'react';
import logo from '../assets/Group2.png';

const SearchBar = props => {
	const { showLogo } = props
	return (
		<div className={'root'}>
			{showLogo && <img src={logo} width="112" height="46" style={{ marginLeft: '6%' }}/>}
			<div
				id="geocoder"
				style={{
					...(!showLogo ? {} : {
						float: 'right'
					})
				}}
			/>
		</div>
	)
}

SearchBar.defaultProps = {
	showLogo: false
}

export default SearchBar
