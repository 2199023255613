import React from 'react';
import { InputPicker} from 'rsuite';
import {Typography} from '@material-ui/core'
import MapInput from '../components/MapInput';
import {data} from '../components/Convert';


// CONTAINER TO ADD ALERT OR MODIFY 

 export default class Demo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type : '',
      alert : '',
    }
    this.setValue = this.setValue.bind(this)
  }
  
  setValue(value)
  {
    this.setState({
      type : value
    })
  }

  render(){
    const {alert} = this.props.location;
    if(alert)
    {
      return(
        <div style={{height: '100%',flexDirection: 'column', display: 'flex', marginLeft: '56px', justifyContent: 'center', alignItems: 'center',backgroundColor: 'rgb(19, 63, 97)', zIndex: 2}}> 
            <div style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center', width : '30%', backgroundColor : 'rgba(255, 255, 255, 0.8)', borderRadius: '14px', padding: '10px'}}>
                <MapInput alert={alert} history={this.props.history}/>
            </div>     
        </div>
      )
    }
    else{
    return (
      <div style={{height: '100%',flexDirection: 'column', display: 'flex', marginLeft: '56px', justifyContent: 'center', alignItems: 'center',backgroundColor: 'rgb(19, 63, 97)', zIndex: 2}}> 
        <div style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center', minHeight: 300, width : '30%', backgroundColor : 'rgba(255, 255, 255, 0.8)', borderRadius: '14px', padding: '10px'}}>
                <div style={{height: '100%', width: '100%'}}>
                    <InputPicker data={data} onChange={(e => this.setValue(e))} size='large' style={{ width: '100%'}}/>
                    {!this.state.type ? 
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
                      <Typography style={{alignSelf: 'center'}}>Choose an Alert</Typography>
                    </div> :
                    <div>
                      <MapInput type={this.state.type} history={this.props.history}/>
                    </div>}
                </div>
            </div>     
        </div>
    );
  }}
}

  
  