import React from 'react';
import Sidebar from './SideBar'
import { LinearProgress, withStyles} from '@material-ui/core';

const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
// LOADER COMPONENT BETWEEEN EACH PAGE 

const ColorLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: '#f44340',
    },
    barColorPrimary: {
      backgroundColor: '#1C5D90',
    },
  })(LinearProgress);

class Loading extends React.Component {
    constructor(props)
    {
        super(props)
    }

    render(){
        return (
                <div>
                    {width > 600 ? <Sidebar admin={this.props.admin}/> : null}                    
                    <div key='loading' style={{position : 'absolute', backgroundColor : '#1C5D90',height : '100%', width : '100%', display : 'flex', alignItems: 'center',justifyContent : 'center'}}>
                        <div style={{display: 'flex', alignSelf: 'center', justifyContent: 'space-between', width: '100%', flexDirection: 'column', alignItems : 'center'}}>
                            <img src={require('../assets/Group2.png')} style={{width : '230px', height : '100px', marginBottom: '15%'}}/>
                            <div style={{alignSelf : 'center', width : '60%', height: '4', marginBottom: '20%'}}>
                                <ColorLinearProgress/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

export default Loading