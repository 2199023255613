import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  Button,
  withStyles,
  Link,
} from '@material-ui/core';
import { Input, InputGroup, Icon } from 'rsuite';
import logo from '../assets/Group2.png';
import axios from 'axios';

const ButtonBlue = withStyles({
  root: {
    '&:hover': {
      backgroundColor: '#164c75',
    },
    backgroundColor: '#1C5D90',
    color: '#fff',
  },
})(Button);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      pass: '',
      getAccess: false,
      name: '',
      lastname: '',
      company: '',
      email: '',
      message: '',
      error: '',
      showPassword: false,
    };
    this.handlechange = this.handlechange.bind(this);
    this.getAccess = this.getAccess.bind(this);
    this.getConnection = this.getConnection.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.LogUser = this.LogUser.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
  }

  componentDidMount()
  {
    const token = localStorage.getItem('TOKEN');
    if(token)
    {
      console.log('token   ' + token)
      axios.post(`/api/checkToken`, {token : token})
      .then(data => {
        console.log('CHECKTOKEN ' + data.data)
        this.props.history.push({
          pathname: '/alerts',
          admin: data.data.admin,
        });
      })
      .catch(e => {
        console.log(e)
      })
    }
  }

  handlechange(text, type) { // onchange for all basic input 
    this.setState({
      [type]: text,
    });
  }

  handleClickShowPassword() { //show password
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  validateEmail(email) { //email regex 
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  sendMail(e) { // method to send an requested access email 
    const body = {
      name: this.state.name,
      lastname: this.state.lastname,
      company: this.state.company,
      email: this.state.email,
      message: this.state.message,
    };
    axios
      .post(`/api/mail`, body)
      .then(data => {
        if (data.status === 200) {
          this.setState({
            getAccess: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          error: err.response.data.error,
        });
      });
  }

  LogUser(e) { //method to log user 
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    const body = {
      username: this.state.username,
      password: this.state.pass,
    };
    //'http://localhost:3400/api/get/threshold'
    //'https://front.data4risk.com/api/login'
    axios
      .post(`/api/login`, body)
      .then(data => {
        console.log(data.data);
        if (data.status === 200) {
            localStorage.setItem('TOKEN', data.data.token); // stock token in localstorage, it will be better if we stock it in header or cookie
              console.log('')
              this.props.history.push({
                pathname: '/alerts',
                admin: data.data.admin,
              });
        }
      })
      .catch(err => {
        console.log(err);
        if (err) {
          this.setState({
            error: err.response
              ? err.response.data.error
              : 'Internal Error Systeme please try again !',
          });
        }
      });
    e.preventDefault();
  }

  getAccess() {
    this.setState({
      getAccess: true,
      error: '',
    });
  }

  getConnection() {
    this.setState({
      getAccess: false,
      error: '',
    });
  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          margin: 0,
          backgroundColor: '#1C5D90',
        }}
      >
        {this.state.getAccess === true ? (
          <div className="login-form-container">
            <div className={'signup-form'}>
              <div className={'form-logo'}>
                <img src={logo} />
              </div>
              <div className="login-form-group">
                <InputGroup inside style={{ width: '300px', marginBottom: 10 }}>
                  <InputGroup.Addon>
                    <Icon icon="avatar" />
                  </InputGroup.Addon>
                  <Input
                    value={this.state.name}
                    placeholder="firstname"
                    onChange={e => this.handlechange(e, 'name')}
                  />
                </InputGroup>
                <InputGroup
                  inside
                  style={{ width: '300px', marginBottom: 10, color: '#1C5D90' }}
                >
                  <InputGroup.Addon>
                    <Icon icon="avatar" />
                  </InputGroup.Addon>
                  <Input
                    value={this.state.lastname}
                    placeholder="lastname"
                    type="text"
                    onChange={e => this.handlechange(e, 'lastname')}
                  />
                </InputGroup>
                <InputGroup
                  inside
                  style={{ width: '300px', marginBottom: 10, color: '#1C5D90' }}
                >
                  <InputGroup.Addon>
                    <Icon icon="people-group" />
                  </InputGroup.Addon>
                  <Input
                    value={this.state.company}
                    placeholder="Company"
                    type="text"
                    onChange={e => this.handlechange(e, 'company')}
                  />
                </InputGroup>
                <InputGroup
                  inside
                  style={{ width: '300px', marginBottom: 10, color: '#1C5D90' }}
                >
                  <InputGroup.Addon>
                    <Icon icon="envelope" />
                  </InputGroup.Addon>
                  <Input
                    value={this.state.email}
                    placeholder="email"
                    type="email"
                    onChange={e => this.handlechange(e, 'email')}
                  />
                </InputGroup>
                <InputGroup
                  inside
                  style={{ width: '300px', marginBottom: 10, color: '#1C5D90' }}
                >
                  <Input
                    componentClass="textarea"
                    rows={3}
                    style={{ minWidth: 'unset' }}
                    onChange={e => this.handlechange(e, 'message')}
                    placeholder="Your message"
                  />
                </InputGroup>
              </div>
              {this.state.error ? (
                <Typography color="error">{this.state.error}</Typography>
              ) : null}
              <div style={{ marginTop: 30, width: '90%' }}>
                <ButtonBlue
                  style={{ width: '-webkit-fill-available' }}
                  onClick={this.sendMail}
                >
                  SUBMIT
                </ButtonBlue>
              </div>
              <Link
                onClick={() => this.getConnection()}
                style={{
                  alignSelf: 'center',
                  color: '#1C5D90',
                  cursor: 'pointer',
                  marginTop: 20,
                }}
              >
                Connexion
              </Link>
            </div>
          </div>
        ) : (
          <div className="login-form-container">
            <form className={'login-form'} onSubmit={this.LogUser}>
              <div className={'form-logo'}>
                <img src={logo} />
              </div>
              <div className="login-form-group">
                <InputGroup inside style={{ width: '300px', marginBottom: 10 }}>
                  <InputGroup.Addon>
                    <Icon icon="avatar" />
                  </InputGroup.Addon>
                  <Input
                    value={this.state.username}
                    placeholder="Username"
                    onChange={e => this.handlechange(e, 'username')}
                  />
                </InputGroup>
                <InputGroup
                  inside
                  style={{ width: '300px', marginBottom: 10, color: '#1C5D90' }}
                >
                  <InputGroup.Addon>
                    <Icon icon="lock" />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    onChange={e => this.handlechange(e, 'pass')}
                  />
                  <InputGroup.Addon
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <div onClick={this.handleClickShowPassword}>
                      <Icon
                        icon={this.state.showPassword ? 'eye' : 'eye-slash'}
                      />
                    </div>
                  </InputGroup.Addon>
                </InputGroup>
              </div>
              {this.state.error ? (
                <Typography color="error">{this.state.error}</Typography>
              ) : null}
              <div style={{ marginTop: 30, width: '90%' }}>
                <ButtonBlue
                  style={{ width: '-webkit-fill-available' }}
                  onClick={this.LogUser}
                  type='submit'
                >
                  Login
                </ButtonBlue>
              </div>
              <Link
                onClick={() => this.getAccess()}
                style={{
                  alignSelf: 'center',
                  color: '#1C5D90',
                  cursor: 'pointer',
                  marginTop: 20,
                }}
              >
                Need an access ?
              </Link>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Login);
