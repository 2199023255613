import React from 'react';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import * as Layer from './AlertLayer2';
import IconButton from '@material-ui/core/IconButton';
import LogoCanicule from '../assets/icon-front/haute-temperature-fil.png';
import LogoCaniculeFill from '../assets/icon-front/haute-temperature-plein.png';
import LogoWind from '../assets/icon-front/vent-fil.png';
import LogoRain from '../assets/icon-front/grosse-pluie-clair.png';
import LogoRainFill from '../assets/icon-front/grosse-pluie-plein.png';
import LogoWindFill from '../assets/icon-front/vent-plein.png';
import LogoExtremeCold from '../assets/icon-front/bas-temperature-fil-.png';
import LogoExtremeColdFill from '../assets/icon-front/bas-temperature-plein.png';
import LogoThunder from '../assets/icon-front/orage-trait.png';
import LogoThunderFill from '../assets/icon-front/orage-plein.png';
import LogoSnow from '../assets/icon-front/neige-trait.png';
import LogoSnowFill from '../assets/icon-front/neige-plein.png';
import LogoIceFill from '../assets/icon-front/verglas-plein.png';
import LogoIce from '../assets/icon-front/verglas-trait.png';
import LogoPollutionFill from '../assets/icon-front/pollution-fondbleu.png';
import LogoPollution from '../assets/icon-front/pollution-filetbleu.png';
import SearchBar from '../components/SearchBar';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import Loading from '../components/Loading';
import AlertAccordionList from '../components/AlertAccordionList';
import { convert } from '../components/Convert';
import LogoSubFill from '../assets/icon-front/icon-vague-plein.png';
import LogoSub from '../assets/icon-front/icon-vague-filet.png';
import LogoCrueFill from '../assets/icon-front/inondation-fondbleu.png';
import LogoCrue from '../assets/icon-front/inondation-filetbleu.png';
import AccoridonItem from '../components/AlertAccordionItem';
import { returnColor } from '../components/Convert';
import { ToastContainer, toast } from 'react-toastify';
import { Button, LinearProgress } from '@material-ui/core';
import DatePickerRange from '../components/DatePickerRange'
import NavBarInstance from '../components/NavBarMobile';
//MAP ALERT CONTAINER/COMPONENT

const LinearLoader = withStyles({
  colorPrimary: {
    backgroundColor: '#f44340',
  },
  barColorPrimary: {
    backgroundColor: '#1C5D90',
  },
})(LinearProgress);

const MBXTOKEN =
  'pk.eyJ1Ijoiam9zZW5kIiwiYSI6ImNsZW9lcndmajAxbTczcm9kZDVmaHAwNmoifQ.uQpmWPQJPlSsAkNMrEnUiA';
const MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder');
const environment = process.env.NODE_ENV || 'development';
const config = require(`../../config/${environment}`);

const PurpleSwitch = withStyles({
  // CUSTOM TOGGLE FOR YELLOW ALERT REQUEST
  switchBase: {
    color: yellow[300],
    '&$checked': {
      color: yellow[500],
    },
    '&$checked + $track': {
      backgroundColor: yellow[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledIconButton = withStyles({
  // custom icon
  root: {
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    marginBottom: '25px',
    backgroundColor: 'rgba(255,255,255,0.91)',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.91)',
    },
  },
})(IconButton);

const arrayLogo = {
  // arraylogo to pick the image of alert dynamically
  wind: [LogoWind, LogoWindFill],
  coldWave: [LogoExtremeCold, LogoExtremeColdFill],
  heatWave: [LogoCanicule, LogoCaniculeFill],
  thunder: [LogoThunder, LogoThunderFill],
  grele: [LogoThunder, LogoThunderFill],
  thunderv2: [LogoThunder, LogoThunderFill],
  thunderv3: [LogoThunder, LogoThunderFill],
  snow: [LogoSnow, LogoSnowFill],
  ice: [LogoIce, LogoIceFill],
  rain: [LogoRain, LogoRainFill],
  submersion: [LogoSub, LogoSubFill],
  crue: [LogoCrue, LogoCrueFill],
  pollution: [LogoPollutionFill, LogoPollution],
};

const weatherTypes = Object.keys(arrayLogo);

const width = window.innerWidth > 0 ? window.innerWidth : screen.width;

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.config = {
      dateRange: 2,
    };
    this.state = {
      startDate: moment(),
      endDate: moment(),
      data: [],
      alerts: [],
      alertLimit: 5,
      activeKey : null,
      isLoading: true,
      isLoadingBis: false,
      country: '',
      yellow: false,
      format : 'raw'
    };
    this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
    this.initializeMap = this.initializeMap.bind(this);
    this.onYellowChange = this.onYellowChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.changeAlertFormatForMobile = this.changeAlertFormatForMobile.bind(this);
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
    // get all alerts when component is mount
    const token = localStorage.getItem('TOKEN');
    Axios.post(`/api/getAlert`, { isFeatureCollection: true, token, yellow: false })
      .then(data => {
        console.log(data.data);
        this.setState({ data: data.data }, () =>
          width > 600
            ? this.initializeMap(data.data)
            : null
        );
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleScroll(event) {
   const myDiv = document.getElementById(this.state.format)
   if(myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight)
   {
     console.log('BOTTOM')
   }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    this.map && this.map.remove();
  }

  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }

  changeStyle(type) {
    // method to display alerts or not when clicking on icon
    const visibility = this.map.getLayer(type).visibility;
    console.log(type)
    if (visibility === 'none') {
      this.map.setLayoutProperty(type, 'visibility', 'visible');
      document.getElementById(type + 'img').src = arrayLogo[type][1];
      return false;
    } else {
      this.map.setLayoutProperty(type, 'visibility', 'none');
      document.getElementById(type + 'img').src = arrayLogo[type][0];
      return true;
    }
  }
  onYellowChange(e) {
    // method to get yellow alerts or not
    this.setState({ yellow: e.target.checked });
  }

  initializeMap(data) {
    // map initialisation
    mapboxgl.accessToken = MBXTOKEN;
    const nav = new mapboxgl.NavigationControl();

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/dark-v9',
      center: [4, 46.9],
      zoom: 5.4,
    });

    this.map.addControl(nav, 'bottom-left');

    this.geocoder = new MapboxGeocoder({
      accessToken: MBXTOKEN,
      mapboxgl: mapboxgl,
      countries: 'FR, GP, MQ, YT',
      marker: false,
      types: 'postcode',
      placeholder: 'Search',
    });

    document
      .getElementById('geocoder')
      .appendChild(this.geocoder.onAdd(this.map));

    this.map.on('load', () => {
      let hoveredStateId = null;

      this.map.addSource('alerts', {
        type: 'geojson',
        data,
        generateId: true,
      });

      this.map.addLayer(Layer.windLayer);
      this.map.addLayer(Layer.coldWaveLayer);
      this.map.addLayer(Layer.heatWaveLayer);
      this.map.addLayer(Layer.iceLayer);
      this.map.addLayer(Layer.snowLayer);
      this.map.addLayer(Layer.heavyRainLayer);
      this.map.addLayer(Layer.submersionLayer);
      this.map.addLayer(Layer.greleLayer);
      this.map.addLayer(Layer.crueLayer);
      this.map.addLayer(Layer.pollutionLayer);
      this.map.addLayer(Layer.thunderLayer);
      this.map.addLayer(Layer.thunderLayerv2);
      this.map.addLayer(Layer.thunderLayerv3);
      this.map.on('click', e => {
        e.preventDefault();
        const relatedFeatures = _.filter(
          this.map.queryRenderedFeatures(e.point),
          feature => {
            return _.get(feature, 'source') === 'alerts';
          }
        );
        if (relatedFeatures.length === 0) {
          this.setState({ alerts: [] });
        }
      });

      weatherTypes.forEach(weatherType => {
        this.map.on('click', weatherType, e => {
          e.stopPropagation;
          if (e.features.length > 0) {
            const relatedFeatures = _.filter(
              this.map.queryRenderedFeatures(e.point),
              feature => {
                return _.get(feature, 'source') === 'alerts';
              }
            );
            if (
              !_.find(this.state.alerts, ['id', e.features[0].properties.id])
            ) {
              this.setState({
                alerts: _.map(relatedFeatures, feature =>
                  _.get(feature, 'properties')
                ),
              });
            }
          }
        });
        this.map.on('mousemove', weatherType, e => {
          this.map.getCanvas().style.cursor = 'pointer';
          if (e.features.length > 0) {
            if (hoveredStateId !== null && hoveredStateId !== undefined) {
              this.map.setFeatureState(
                { source: 'alerts', id: hoveredStateId },
                { hover: false }
              );
            }
            hoveredStateId = e.features[0].id;
            this.map.setFeatureState(
              { source: 'alerts', id: hoveredStateId },
              { hover: true }
            );
          }
        });
        this.map.on('mouseleave', weatherType, e => {
          this.map.getCanvas().style.cursor = '';
          if (hoveredStateId !== null) {
            this.map.setFeatureState(
              { source: 'alerts', id: hoveredStateId },
              { hover: false }
            );
          }
        });
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.endDate != prevState.endDate ||
      this.state.startDate != prevState.startDate ||
      this.state.yellow != prevState.yellow ||
      this.state.country != prevState.country
    ) {
      this.setState({ isLoadingBis: true, alerts: [] });
      this.getNewAlert();
    }
  }

  changeCountry(country) {
    if (this.state.country === country) this.setState({ country: '' });
    else this.setState({ country: country });
  }

  getNewAlert() {
    const token = localStorage.getItem('TOKEN');
    const { country, startDate, endDate, yellow } = this.state
    const startDateTimeStamp = startDate.unix()
    const endDateTimeStamp = endDate.unix()
    Axios.post(`/api/getAlert`, {
      isFeatureCollection: true,
      token,
      country,
      yellow,
      startDate: startDateTimeStamp === endDateTimeStamp ? false : startDate.utc().format('YYYY-MM-DD'),
      endDate: startDateTimeStamp === endDateTimeStamp ? false : endDate.utc().format('YYYY-MM-DD'),
    })
      .then(data => {
        if (data) {
          this.map && this.map.getSource('alerts').setData(data.data);
          setTimeout(() => {
            this.setState({ isLoadingBis: false });
          }, 3000);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleChangeDateRange({ startDate, endDate }) {
    this.setState({
      startDate: moment(startDate),
      endDate: moment(startDate).add(2, 'days')
    })
  }

  changeAlertFormatForMobile(value)
  {
    this.setState({format : value})
  }

  render() {
    const {activeKey} = this.state
    if (width < 600) {
      const items = _.take(_.map(this.state.data.features, feat => feat.properties), this.state.alertLimit)
      return (
        <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            zIndex: 2,
            marginBottom : 56
          }}
        >{  this.state.format === 'detailled' ?
        <div style={{flex : 1, justifyContent: 'center', }} id={this.state.format}>
          <AlertAccordionList
            disableCollapse
            items={_.take(_.map(this.state.data.features, feat => feat.properties), this.state.alertLimit)}
          />
          <Button
            onClick={() => {
              this.setState((prevState) => ({
                alertLimit: prevState.alertLimit + 5
              }))
            }}
            style={{width: '100%'}}
            variant='contained'>
            Plus
          </Button>
         </div>
         :
         <div style={{flex : 1, justifyContent: 'center', }} id={this.state.format}>
         {
           <pre >
           {JSON.stringify(items, null, 2)}
            </pre>
         }
          <div style={{alignSelf: 'center', width: '100%'}}>
          <Button
          onClick={() => {
            this.setState((prevState) => ({
              alertLimit: prevState.alertLimit + 5
            }))
          }}
          style={{width: '100%'}}
          variant='contained'>
          Plus
        </Button>
        </div>
       </div>
        }
        </div>
          <NavBarInstance activeKey={activeKey} onSelect={this.handleSelect} history={this.props.history} changeAlertFormatForMobile={this.changeAlertFormatForMobile}/>
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{
              maxWidth: 360,
              position: 'absolute',
              marginLeft: 100,
              marginTop: 50,
              zIndex: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <SearchBar/>
            <DatePickerRange
              disableEndDate
              startDate={this.state.startDate.toDate()}
              endDate={this.state.endDate.toDate()}
              onChange={this.handleChangeDateRange}
              onNextStartDate={() => this.handleChangeDateRange({ startDate: this.state.startDate.add(1, 'days') })}
              onPrevStartDate={() => this.handleChangeDateRange({ startDate: this.state.startDate.subtract(1, 'days') })}
            />
            <AlertAccordionList items={this.state.alerts}/>
            <ToastContainer/>
          </div>
          <div
            ref={el => (this.mapContainer = el)}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              marginLeft: '56px',
              zIndex: 2,
            }}
          >
            {this.state.isLoadingBis ? <Loader /> : null}
          </div>
          <div
            style={{
              position: 'absolute',
              zIndex: 2,
              marginLeft: '87%',
              marginTop: 50,
              width: 200,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-center',
                  height: 'min-content'
                }}
              >
                <StyledIconButton
                  title="Cold wave"
                  onClick={e => this.changeStyle('coldWave', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoExtremeColdFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="coldWaveimg"
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="Heat wave"
                  onClick={e => this.changeStyle('heatWave', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoCaniculeFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="heatWaveimg"
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="grele"
                  onClick={e => this.changeStyle('grele', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoThunderFill}
                    width="30px"
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id='greleimg'
                  />
                </StyledIconButton>
                 <StyledIconButton
                  title="Thunder_V2"
                  onClick={e => this.changeStyle('thunderv2', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoThunderFill}
                    width="30px"
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id='thunderv2img'
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="Thunder_V3"
                  onClick={e => this.changeStyle('thunderv3', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoThunderFill}
                    width="30px"
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id='thunderv3img'
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="Wind"
                  onClick={e => this.changeStyle('wind', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoWindFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="windimg"
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="france"
                  onClick={() => [this.changeCountry('france')]}
                  className="iconButton"
                  style={{
                    backgroundColor:
                      this.state.country === 'france' ||
                      this.state.country === ''
                        ? 'white'
                        : 'rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <img
                    src={require('../assets/france.png')}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="italy"
                  onClick={() => [this.changeCountry('italy')]}
                  className="iconButton"
                  style={{
                    backgroundColor:
                      this.state.country === 'italy' ||
                      this.state.country === ''
                        ? 'white'
                        : 'rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <img
                    src={require('../assets/italie.png')}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                  />
                </StyledIconButton>
                <div
                  style={{
                    width: 'min-content',
                    height: 'min-content',
                    borderRadius: '15px',
                    backgroundColor: 'white',
                  }}
                >
                  <PurpleSwitch
                    title="yellow level ?"
                    color="primary"
                    onChange={this.onYellowChange}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: 'fit-content',
                }}
              >
                <StyledIconButton
                  title="Thunder"
                  onClick={e => this.changeStyle('thunder', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoThunderFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="thunderimg"
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="Snow"
                  onClick={e => this.changeStyle('snow', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoSnowFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="snowimg"
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="Ice"
                  onClick={e => this.changeStyle('ice', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoIceFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="iceimg"
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="Heavy rain"
                  onClick={e => this.changeStyle('rain', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoRainFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="rainimg"
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="Submersion"
                  onClick={e => this.changeStyle('submersion', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoSubFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="submersionimg"
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="Crue"
                  onClick={e => this.changeStyle('crue', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoCrueFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="crueimg"
                  />
                </StyledIconButton>
                <StyledIconButton
                  title="Pollution"
                  onClick={e => this.changeStyle('pollution', e)}
                  className="iconButton"
                >
                  <img
                    src={LogoPollutionFill}
                    width={window.innerHeight * 0.025}
                    height={window.innerHeight * 0.025}
                    id="pollutionimg"
                  />
                </StyledIconButton>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const Loader = props => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          backgroundColor: '#1C5D90',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'space-between',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={require('../assets/Group2.png')}
            style={{ width: '230px', height: '100px', marginBottom: '15%' }}
          />
          <div
            style={{
              alignSelf: 'center',
              width: '60%',
              height: '10%',
              marginBottom: '20%',
            }}
          >
            <LinearLoader />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Map;
