import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { CalendarWhite } from '../Convert'
import CustomInput from './CustomInput'
import DatePicker from 'react-datepicker'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  calendarIcon: {
    marginLeft: 5,
    marginRight: 5
  },
  datePickerInput: {
    '&:disabled': {
      cursor: 'not-allowed'
    }
  }
})

const DatePickerRange = props => {
  const {
    dateFormat,
    disableStartDate,
    disableEndDate,
    onChange,
    onNextStartDate,
    onNextEndDate,
    onPrevStartDate,
    onPrevEndDate,
    startDate,
    endDate
  } = props
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <DatePicker
        customInput={<CustomInput onNext={onNextStartDate} onPrev={onPrevStartDate}/>}
        dateFormat={dateFormat}
        disabled={disableStartDate}
        // startDate={startDate}
        // endDate={endDate}
        onChange={date => onChange({ startDate: date, endDate })}
        selected={startDate}
        withPortal
      />
      <CalendarWhite className={classes.calendarIcon}/>
      <DatePicker
        customInput={<CustomInput onNext={onNextEndDate} onPrev={onPrevEndDate}/>}
        dateFormat={dateFormat}
        disabled={disableEndDate}
        // startDate={startDate}
        // endDate={endDate}
        minDate={startDate}
        onChange={date => onChange({ startDate, endDate: date })}
        selected={endDate}
        withPortal
      />
    </div>
  )
}

DatePickerRange.propTypes = {
  dateFormat: PropTypes.string,
  disableStartDate: PropTypes.bool,
  disableEndDate: PropTypes.bool,
  /**
   * change startDate and endDate
   * @param {object} values
   * @param {Date} values.startDate
   * @param {Date} values.endDate
   */
  onChange: PropTypes.func,
  onNextStartDate: PropTypes.func,
  onNextEndDate: PropTypes.func,
  onPrevStartDate: PropTypes.func,
  onPrevEndDate: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date)
}

DatePickerRange.defaultProps = {
  dateFormat: 'dd/MM/yyyy',
  disableStartDate: false,
  disableStartDate: false
}

export default DatePickerRange
