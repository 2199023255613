const questionsAerial = [
  { label: 'Solar panels', question_type: 'radio' },
  { label: 'Degraded roofs', question_type: 'radio' },
  { label: 'Swimming pools', question_type: 'radio' },
]

const questionsStreet = [
  { label: 'Graffiti', question_type: 'radio' },
  { label: 'Cracks', question_type: 'radio' },
  { label: 'Walled windows', question_type: 'radio' },
  { label: 'Number of floors', question_type: 'slider' },
]

const questionsOther = [
  { label: 'Rating', question_type: 'rating' },
  { label: 'Comments', question_type: 'edit' },
]

export {
  questionsAerial, questionsStreet, questionsOther
}