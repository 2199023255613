import React from 'react';
import mapboxgl from 'mapbox-gl';
import * as layers from './MapLayers.js';
import franceGeo from '../data/FranceGeoJson.json';
import SearchBar from '../components/SearchBar';
import { Typography, Divider} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Accordion  from '../components/Accordion';
import LogoCanicule from '../assets/icon-front/haute-temperature-fil.png';
import LogoWind from '../assets/icon-front/vent-fil.png';
import LogoSnow from '../assets/icon-front/neige-trait.png';
import LogoExtremeCold from '../assets/icon-front/bas-temperature-fil-.png';
import LogoIce from '../assets/icon-front/verglas-trait.png';
import Content from './Content';
var MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder');
import Axios from 'axios';
import {convert} from '../components/Convert';
import DatePicker from 'react-datepicker';

const environment = process.env.NODE_ENV || 'development'
const config = require(`../../config/${environment}`)

// class Map extends React.Component {
//   componentDidMount() {
//     this.map = new mapboxgl.Map({
//       container: this.mapContainer,
//       style: 'mapbox://styles/mapbox/outdoors-v10?optimize=true',
//       center: [0.33, 46.52],
//       zoom: 5.5
//     })
//   }
//   render() {
//     const style = {
//       position: 'absolute',
//       top: 0,
//       bottom: 0,
//       width: '100%',
//       marginLeft: '56px',
//     };
//     return (
//       <div style={style} ref={(el) => this.mapContainer = el}/>
//     )
//   }
// }


// THRESHOLD MAP COMPONENT, a bit complexe



class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scale: 'region',
      features: '',
      data : franceGeo, // objet dans lequel est referencé le path de chaque geojson dans le dossier repo
      dataAlert : {},
      intScale : 0, 
      parentCode : '',
      parentNom : '',
      current : 'France',
      query : '',
      currentCanicule : {},
      currentExtremeCold : {},
      currentWind : {},
      open: false,
      date : new Date(),
      realDate : convert(new Date())
    };
    
    this.removeMap = this.removeMap.bind(this);
    this.changeParent = this.changeParent.bind(this);
    this.getCurrentTreshold = this.getCurrentTreshold.bind(this);
    this.onClickAlert = this.onClickAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(prevProps) {
    mapboxgl.accessToken = process.env.MAPBOXTOKEN;
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/outdoors-v10?optimize=true',
      center: [0.33, 46.52],
      zoom: 5.5
    });

    this.geocoder = new MapboxGeocoder({
      accessToken : process.env.MAPBOXTOKEN,
      mapboxgl : mapboxgl,
      countries : 'FR, GP, MQ, YT',
      marker : false,
      types : 'postcode',
      placeholder : 'Search'
      });

      this.geocoder.on('result', function (data) {
          if(data.result.text >= '20200' && data.result.text <= '20620')
          {
            this.setState({
              current : data.result.place_name,
              query : data.result.text,
              scale : 'departement',
              features : 'departement-2B',
              parentCode : this.state.data['departement-2B'].parentCode,
              parentNom : this.state.data['departement-2B'].parent,
            })
          }
          else if(data.result.text >= '20000' && data.result.text <= '20190')
          {
            this.setState({
              current : data.result.place_name,
              query : data.result.text,
              scale : 'departement',
              features : 'departement-2A',
              parentCode : this.state.data['departement-2A'].parentCode,
              parentNom : this.state.data['departement-2A'].parent,
            })
          }
          else{
            this.setState({
              current : data.result.place_name,
              query : data.result.text,
              scale : 'departement',
              features : 'departement-'+data.result.place_name.substring(0,2),
              parentCode : this.state.data['departement-'+data.result.place_name.substring(0,2)].parentCode,
              parentNom : this.state.data['departement-'+data.result.place_name.substring(0,2)].parent,
            })
          }
          this.geocoder.clear()
    }.bind(this))

    document.getElementById('geocoder').appendChild(this.geocoder.onAdd(this.map));

    this.map.on('load', () => {
      let hoveredFeatureId = null
      this.map.addSource('carte', {
        data:  this.state.data['france'].maps[this.state.intScale].href,
        type: 'geojson',
        generateId: true
      });

      this.map.addLayer(layers.fillLayer);
      this.map.addLayer(layers.strokeLayer);
      //MOUSE MOVE METHOD, SET CARTE-HOVER FILTER
      this.map.on('mousemove', 'carte', e => { //hover effect on geojson
        if (this.state.scale != 'departement' && this.state.scale != 'region')
          return;
        var features = this.map.queryRenderedFeatures(e.point, {
          layers: ['carte'],
        });

        if (features.length > 0) {
          if (hoveredFeatureId || hoveredFeatureId === 0) {
            this.map.setFeatureState({
              source: 'carte',
              id: hoveredFeatureId
            }, {
              hover: false
            })
          }
          hoveredFeatureId = features[0].id
          this.map.setFeatureState({
            source: 'carte',
            id: hoveredFeatureId
          }, {
            hover: true
          })
        }
      });

      //MOUSE OUT METHOD REMOVE CARTE-HOVER FILTER
      this.map.on('mouseleave','carte', e => {
        if (this.state.scale != 'departement' && this.state.scale != 'region')
          return;
        if (hoveredFeatureId || hoveredFeatureId === 0) {
          this.map.setFeatureState({
            source: 'carte',
            id: hoveredFeatureId
          }, {
            hover: false
          })
        }
      });

      //ONCLICK METHOD, BOUNDS TO AREA CLICK, 
      this.map.on('click', 'carte', e => { // onclick, get data, zoomin on area by clicking
        if (this.state.scale != 'departement' && this.state.scale != 'region')
          return;
        var features = this.map.queryRenderedFeatures(e.point, {
          layers: ['carte'],
        });
        if (!features.length) return;
        var feature = features[0];
        var slug = this.state.scale + '-' + feature.properties.code;
        if(this.state.features == '' || this.state.features.includes('region'))
        {
          this.setState({
            features: slug,
            parentCode : this.state.data[slug].parentCode,
            parentNom : this.state.data[slug].parent,
            current : this.state.data[slug].title,
            currentCode : feature.properties.code,
            featuresCode : undefined,
            scale : 'departement',
            query : '',
            intScale : this.state.intScale === 0 ? 1 : 0,
          });
        }
        // update map
        this.map.getSource('carte').setData(this.state.data[this.state.features].maps[this.state.intScale].href); // 

        // update boundingBox
        let bbox = this.state.data[this.state.features].bbox
        if (bbox) {
            this.map.fitBounds(bbox, {
                padding: 100,
                offset: [0, 0]
            });
        }
      });
      
    });
  }


  getCurrentTreshold() {
    const body = {
      current : this.state.query ? this.state.query : this.state.features,
      date : this.state.realDate,
      token : localStorage.getItem('TOKEN')
    }
    if(body.current == 'france' || body.current == '')
      return;
    //'http://localhost:3400/api/get/threshold'
    //'https://front.data4risk.com/api/get/threshold'

    Axios.post(`/api/get/threshold`, body)
    .then(data => {
      const _data = data.data[0];
      if(!_data)
        return;
      this.setState({
        currentCanicule : _data.canicule ? _data.canicule : null,
        currentExtremeCold : _data.extremeCold ? _data.extremeCold : null,
        currentWind : _data.wind ? _data.wind : null
      })
    })
    .catch(err => {
      if(err.response.status === 400)
        this.props.history.push({pathname : '/',})
      console.log(err)
    })
  }

  componentWillUnmount() {
    this.map.remove();
  }
  
  shouldComponentUpdate(prevProps, prevState)
  {
    if(this.state.features != prevState.features  && this.state.features != '' || this.state.features == '' && this.state.query != prevState.query || this.state.scale != prevState.scale ||
    this.state.currentCanicule != prevState.currentCanicule || this.state.currentExtremeCold != prevState.currentExtremeCold || this.state.currentWind != prevState.currentWind || this.state.realDate !== prevState.realDate)
    {
      return true
    }
    return false
  }

  componentDidUpdate(prevProps, prevState)
  {
    if(this.state.query != prevState.query || prevState.realDate !== this.state.realDate)
    {
      this.setState({
        currentCanicule : {},
        currentExtremeCold: {},
        currentWind : {}
      })
      if(this.state.query)
        this.getCurrentTreshold();
    }

    if(this.state.scale == 'departement' && this.state.features.includes('region') && this.state.features != prevState.features && this.state.query == '')
    {
      //this.map.setFilter('carte-hover', ['==', 'code', '']); //reset filters
      this.map.getSource('carte').setData(this.state.data[this.state.features == '' ? 'france' : this.state.features].maps[this.state.intScale].href);
    }

    else if(prevState.scale != this.state.scale && this.state.query == '' && this.state.features !== prevState.features)
    {
      //this.map.setFilter('carte-hover', ['==', 'code', '']); //reset filters
      this.map.getSource('carte').setData(this.state.data[this.state.features == '' ? 'france' : this.state.features].maps[this.state.intScale].href);
    }

    else if(this.state.current == 'France' && this.state.features == 'france')
    {
      this.setState({
        currentCode : '',
        features : '',
        parentCode : '',
        parentNom : '',
        featuresCode : undefined,
        query : '',
        scale : 'region',
        currentCanicule : {},
        currentExtremeCold : {},
        currentWind : {},
        intScale : 0
      })
      this.map.getSource('carte').setData(this.state.data[this.state.features == '' ? 'france' : this.state.features].maps[this.state.intScale].href);
    }
  }
  
  removeMap(){
    this.map.remove();
  }

  changeParent(e)
  {
    this.setState({
      featuresCode : '',
      features : this.state.parentCode,
      parentCode : this.state.data[this.state.parentCode].parentCode,
      current : this.state.parentNom ,
      parentCode : this.state.data[this.state.parentCode].parentCode,
      parentNom : this.state.data[this.state.parentCode].parent,
      intScale : this.state.intScale === 0 ? 1 : 0,
    }, (()=> {
      if(this.state.query)
      {
        this.setState({
          query : ''
        })
        this.inputElement.click();
        //this.map.getSource('carte').setData(this.state.data[this.state.parentCode].maps[this.state.intScale].href);
      }
    }))
    let bbox = this.state.data[this.state.parentCode].bbox
    if (bbox) {
        this.map.fitBounds(bbox, {
            padding: 100,
            offset: [0, 0]
        });
    }
  }

  onClickAlert()
  {
    if(this.state.query)
    {
      this.setState({
        open : true
      })
    }
  }

  handleChange(e){
    this.setState({
        date : e,
        realDate : convert(e)
    })
}
  render() {
    const style = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      marginLeft: '56px',
    };
    return (
      <div>
        <div style={{position: 'absolute', zIndex: 2, top: '10%', right: '2%',}}>
          <Content state={this.state}/>
          {
            this.state.query ? 
            <div style={{display : 'flex', padding: '10px', justifyContent : 'center'}}>
              <DatePicker
                dateFormat="dd/MM/yyyy"           
                selected={this.state.date}
                onChange={ e => this.handleChange(e)}
                style={{left : 0, zIndex : 999, position: 'absolute'}}
              />
            </div>
            :
            null
          }
        </div>
        <div style={{position: 'absolute', zIndex: 2, marginLeft: 76, marginTop: 26}}>
          <SearchBar showLogo/>
            <div style={{backgroundColor : ' rgba(255,255,255,0.91)',  borderRadius : '15px', width : '400px', boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12'}}>
              <div style={{ padding : '15px', display : 'flex'}}>
                {this.state.parentCode ?
                      <IconButton className={'iconButton'} onClick={this.changeParent} ref={input => this.inputElement = input} aria-label="Menu" style={{float: 'left'}}>
                        <ChevronLeft />
                      </IconButton> : null}
                    <Typography variant="h5" style={{padding : '12px', paddingLeft : '12px'}}>{this.state.current}</Typography>
              </div>
              <div>
                <Divider/>
                <Accordion getCurrentTreshold={this.getCurrentTreshold} title='Heatwave' state='canicule' current={!this.state.query ? this.state.features : this.state.query} features={this.state.features} logo={LogoCanicule} getTreshold={this.getCurrentTreshold}/>
                <Divider/>
                <Accordion getCurrentTreshold={this.getCurrentTreshold} title='Coldwave' state='extremeCold' current={!this.state.query ? this.state.features : this.state.query} features={this.state.features} logo={LogoExtremeCold} getTreshold={this.getCurrentTreshold}/>
                <Divider/>
                <Accordion getCurrentTreshold={this.getCurrentTreshold} title='Wind' state='wind' current={!this.state.query ? this.state.features : this.state.query} features={this.state.features} logo={LogoWind} getTreshold={this.getCurrentTreshold}/>
                <Divider/>
                <Accordion getCurrentTreshold={this.getCurrentTreshold} title='Snow' state='snow' current={!this.state.query ? this.state.features : this.state.query} features={this.state.features} logo={LogoSnow} getTreshold={this.getCurrentTreshold}/>
                <Divider/>
                <Accordion getCurrentTreshold={this.getCurrentTreshold} title='Ice' state='ice' current={!this.state.query ? this.state.features : this.state.query} features={this.state.features} logo={LogoIce} getTreshold={this.getCurrentTreshold}/>
              </div>
            </div>
        </div>
        <div style={style} ref={el => (this.mapContainer = el)} />
      </div>
    );
  }
}

export default Map;