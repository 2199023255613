import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    borderRadius: 15,
    border: '1px solid #aeaeae'
  },
  input: {
    border: 'none',
    textAlign: 'center',
    minWidth: 0,
    '&:disabled': {
      background: 'white'
    }
  }
})

const CustomInput = React.forwardRef((props, ref) => {
  const { disabled, onChange, onNext, onPrev, value, ...rest } = props
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <IconButton disabled={disabled} onClick={onPrev} size='small'>
        <ArrowLeftIcon/>
      </IconButton>
      <input
        {...rest}
        className={classes.input}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        ref={ref}
        style={{
          cursor: disabled ? 'not-allowed' : 'default'
        }}
        type='text'
        value={value}
      />
      <IconButton disabled={disabled} onClick={onNext} size='small'>
        <ArrowRightIcon/>
      </IconButton>
    </div>
  )
})

CustomInput.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func
}

export default CustomInput
