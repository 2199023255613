import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import randomColor from 'randomcolor'
import { useTranslation } from 'react-i18next'
import AlertUnit from '../../data/unit'
import { returnColor } from '../Convert'

import {
  AlertLine,
  AlertBoxPlot,
} from '../AlertChart'
import AppBar from '@material-ui/core/AppBar'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />
})

const AlertVisualisationDetail = props => {
  const {
    alertDetail,
    onClose,
    open,
  } = props

  const [data, setData] = React.useState({})
  const { t } = useTranslation()
  const alertType = _.get(alertDetail, 'type')
  const codePostal = _.get(alertDetail, 'postal_code')
  const country = _.get(alertDetail, 'country')

  const parseLineData = (originalData) => {
    return _.reduce(originalData, (result, { key, data }) => {
      const avgData = _.map(data, ({ avg, date }) => ({ [`avg_${_.toLower(key)}`]: avg, date }))
      _.forEach(avgData, d => {
        const index = _.findIndex(result, ['date', d.date])
        if (index !== -1) {
          result[index] = { ...result[index], ...d }
        } else {
          result.push(d)
        }
      })
      return result
    }, [])
  }

  React.useEffect(() => {
    if (open) {
      axios.post(`/api/getRawAlert`, {
          type: alertType,
          postal_code: codePostal,
          expected_at: moment.utc(_.get(alertDetail, 'expected_at')).toISOString(),
          estimated_end: moment.utc(_.get(alertDetail, 'estimated_end')).toISOString(),
          country: country,
          token: localStorage.getItem('TOKEN')
        })
        .then(res => {
          const { data } = res
          setData(_.map(data, d => ({
            ...d,
            unit: _.findKey(AlertUnit, (variables) => _.includes(variables, _.toLower(d.key)))
          })))
        })
        .catch(console.error)
    }
  }, [open])

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      scroll='paper'
      TransitionComponent={Transition}
    >
      <AppBar position='static' style={{backgroundColor: '#1C5D90'}}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={onClose} style={{ marginRight: 12 }}>
            <CloseIcon />
          </IconButton>
          <Typography style={{ flexGrow: 1 }} variant='h6'>
            {alertType}
          </Typography>
          <Typography variant='subtitle1'>
            {`${codePostal} - ${country}`}
            <span
              style={{
                display: 'inline-block',
                height: 10,
                width: 50,
                backgroundColor: returnColor(_.get(alertDetail, 'level')),
                alignSelf: 'center',
                marginLeft: 20,
                borderRadius: '14px'
              }}
            />
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container>
          {_.map(_.groupBy(data, 'unit'), (groupedData, unit) => {
            const lineData = parseLineData(groupedData)
            if (!lineData.length || lineData.length === 1)
              return null
            return (
              <Grid key={unit} item xs={12} md={6}>
                <Grid container justify='center'>
                  <AlertLine
                    alertType={alertType}
                    data={lineData}
                    keys={_.map(groupedData, ({key}) => `avg_${_.toLower(key)}`)}
                    colors={randomColor({ count: groupedData.length, luminosity: 'dark' })}
                    width={700}
                    height={420}
                    verticalAxisUnit={unit === 'undefined' ? '' : unit}
                  />
                </Grid>
              </Grid>
            )
          })}
          {_.map(data, (d) => (
            <Grid key={`boxplot_${d.key}`} item xs={12} md={6}>
              <Grid container justify='center'>
                <AlertBoxPlot
                  data={d.data}
                  dataKey={t(`aggregatedAlert:${_.toLower(alertType)}.${_.toLower(d.key)}`)}
                  width={700}
                  height={420}
                  verticalAxisUnit={d.unit}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

AlertVisualisationDetail.propTypes = {
  alertDetail: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

AlertVisualisationDetail.defaultProps = {
  alertDetail: {},
  open: false
}

export default AlertVisualisationDetail
